import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { SourceType } from '@trello/analytics-types';
import { Analytics } from '@trello/atlassian-analytics';
import { workspaceState } from '@trello/workspace-state';

import {
  type ExperimentVariations,
  type FeatureExperimentKeys,
  type FeatureExperimentParameters,
} from './data/featureGates';
import {
  getFeatureGatesClientCache,
  getWorkspaceCacheKey,
} from './state/featureGatesClientSharedState';

const _fireExposureEvent = <T extends FeatureExperimentKeys>(
  experimentName: T,
  value: ExperimentVariations<T, FeatureExperimentParameters<T>>,
) => {
  FeatureGates.manuallyLogExperimentExposure(experimentName);
  Analytics.sendTrackEvent({
    action: 'exposed',
    actionSubject: 'feature',
    source: 'trello' as SourceType,
    attributes: {
      experimentName,
      value,
    },
  });
};

/**
 * Async method for doing a one-time async fetch of an experiment value.
 * Waits for the client to be initialized before returning the value.
 */
export const getExperimentValueAsync = async <T extends FeatureExperimentKeys>(
  experimentName: T,
  parameter: FeatureExperimentParameters<T>,
  fireExposureEvent: boolean = true,
): Promise<ExperimentVariations<T, FeatureExperimentParameters<T>>> => {
  return new Promise((resolve) => {
    const featureGatesClientCache = getFeatureGatesClientCache();
    const getExperimentValueFromCacheAsync = (
      workspaceId: typeof workspaceState.value.workspaceId,
    ): Promise<ExperimentVariations<T, FeatureExperimentParameters<T>>> => {
      return new Promise((res) => {
        const experimentConfig =
          featureGatesClientCache.value[getWorkspaceCacheKey(workspaceId)]
            ?.configs?.[experimentName];

        if (experimentConfig !== undefined) {
          const experimentValue = experimentConfig[parameter] ?? 'not-enrolled';
          if (fireExposureEvent) {
            _fireExposureEvent(experimentName, experimentValue);
          }
          res(experimentValue);
          return;
        }
        // cache is empty, wait for value to be set.
        const unsubscribe = featureGatesClientCache.subscribe((state) => {
          const config =
            state[getWorkspaceCacheKey(workspaceId)]?.configs?.[experimentName];
          if (config !== undefined) {
            const val = config[parameter] ?? 'not-enrolled';
            if (fireExposureEvent) {
              _fireExposureEvent(experimentName, val);
            }
            res(val);
            unsubscribe();
          }
        });
      });
    };
    if (!workspaceState.value.isLoading) {
      resolve(
        getExperimentValueFromCacheAsync(workspaceState.value.workspaceId),
      );
      return;
    }
    const unsubscribe = workspaceState.subscribe((state) => {
      if (!state.isLoading) {
        resolve(getExperimentValueFromCacheAsync(state.workspaceId));
        unsubscribe();
      }
    });
  });
};
