import type { RouteIdType } from './routes';
import { RouteId } from './routes';

const organizationRoutes = new Set<RouteIdType>([
  RouteId.WORKSPACE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.ACCOUNT,
  RouteId.WORKSPACE_BILLING,
  RouteId.BILLING,
  RouteId.ORGANIZATION_BOARDS,
  RouteId.OLD_ORGANIZATION_GUESTS,
  RouteId.OLD_ORGANIZATION_REQUESTS,
  RouteId.OLD_ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_BY_ID,
  RouteId.ORGANIZATION_EXPORT,
  RouteId.ORGANIZATION_FREE_TRIAL,
  RouteId.OLD_ORGANIZATION_FREE_TRIAL,
  RouteId.ORGANIZATION_GUESTS,
  RouteId.ORGANIZATION_REQUESTS,
  RouteId.ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_POWER_UPS,
  RouteId.OLD_ORGANIZATION_POWER_UPS,
  RouteId.ORGANIZATION_TABLES,
  RouteId.OLD_ORGANIZATION_TABLES,
]);

export const isFullScreenWorkspaceRoute = (routeId: RouteIdType): boolean =>
  organizationRoutes.has(routeId);
