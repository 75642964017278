import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { TrelloCard, TrelloPowerUp } from '../generated';

export const formatTrelloPowerUpData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const powerUpData = readField<TrelloCard['powerUpData']>(
    'powerUpData',
    trelloCardRef,
  );
  const formattedPowerUpDataEdges = powerUpData?.edges
    ?.map((edge) => {
      const nodeRef = edge.node;
      const objectId = readField<string>('objectId', nodeRef);
      if (objectId === undefined) {
        return null;
      }
      const value = readField<string>('value', nodeRef);
      const powerUpRef = readField<TrelloPowerUp>('powerUp', nodeRef);
      const powerUpId = readField<string>('objectId', powerUpRef);
      return {
        node: {
          objectId,
          powerUp: {
            objectId: powerUpId,
          },
          value,
        },
      };
    })
    .filter((edge) => edge !== null);

  return formattedPowerUpDataEdges
    ? { edges: formattedPowerUpDataEdges }
    : null;
};
