import { isString } from './validateHelpers';

export type TrelloCardPowerUpData = {
  edges: Array<{
    node: {
      objectId?: string | null;
      powerUp?: {
        objectId?: string | null;
      };
      value?: string | null;
    };
  }>;
};

export const validateTrelloCardPowerUpData = (
  cardPowerUpFields: TrelloCardPowerUpData | undefined,
): boolean => {
  if (cardPowerUpFields === undefined) {
    return false;
  }

  return (
    Array.isArray(cardPowerUpFields?.edges) &&
    cardPowerUpFields.edges.every((edge) => {
      const node = edge.node;
      if (node.powerUp === undefined) {
        return false;
      }
      return (
        isString(node.objectId) &&
        isString(node.powerUp.objectId) &&
        isString(node.value)
      );
    })
  );
};
