import { isBool, isNumber, isString, nullOrString } from './validateHelpers';

export type TrelloStickerFields = {
  edges: Array<{
    node: {
      objectId?: string;
      image?: string | null;
      imageScaled: Array<{
        objectId?: string | null;
        height?: number | null;
        scaled?: boolean | null;
        url?: string | null;
        width?: number | null;
      }> | null;
      left?: number | null;
      rotate?: number | null;
      top?: number | null;
      url?: string | null;
      zIndex?: number | null;
    };
  }> | null;
};

export const validateTrelloStickerData = (
  stickerFields: TrelloStickerFields | undefined,
): boolean => {
  if (stickerFields === undefined) {
    return false;
  }

  return (
    Array.isArray(stickerFields?.edges) &&
    stickerFields.edges.every((edge) => {
      const node = edge.node;
      return (
        isString(node.objectId) &&
        isString(node.image) &&
        isNumber(node.left) &&
        isNumber(node.rotate) &&
        isNumber(node.top) &&
        isString(node.url) &&
        isNumber(node.zIndex) &&
        Array.isArray(node.imageScaled) &&
        node.imageScaled.every((image) => {
          return (
            nullOrString(image.objectId) &&
            isNumber(image.height) &&
            (isBool(image.scaled) || image.scaled === null) &&
            isNumber(image.width) &&
            isString(image.url)
          );
        })
      );
    })
  );
};
