/* eslint-disable eqeqeq
 */
// Generated by CoffeeScript 1.12.7
/*
  Because of the way this file was decompiled, typescript cannot detect its type
  That is why we use `@ts-ignore` in every place we reference this.
  If you're here to convert it to a class, please make sure to clean all the related `@ts-ignore`s
*/

import { mixin } from 'hearsay';
import { clone, find, result, uniqueId, values } from 'underscore';

import Backbone from '@trello/backbone';
import { sendErrorEvent } from '@trello/error-reporting';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { defaultRouter_DO_NOT_USE } from '@trello/router/legacy-router';

import { ModelCache } from 'app/scripts/db/ModelCache';
import { l } from 'app/scripts/lib/localize';
import { SchedulingMixin } from 'app/scripts/lib/scheduling-mixin';
import type { TrelloModel } from 'app/scripts/models/internal/TrelloModel';
import { SubviewRemover } from 'app/scripts/views/internal/SubviewRemover';
import { PostRender } from 'app/scripts/views/lib/PostRender';

const hasProp = {}.hasOwnProperty;
const slice = [].slice;

// This reflects a type from the `hearsay` package
export interface Slot<T> {
  get: () => T;
  set: (value: T) => T;
}

export const VIGOR = {
  NONE: 0,
  SOME: 1,
  LOTS: 2,
};

export interface ViewOptions {
  [key: string]: unknown;
}

export interface View<
  // @ts-expect-error
  TModel extends TrelloModel = TrelloModel,
  TOptions extends ViewOptions = ViewOptions,
  TElement extends Element = HTMLElement,
> extends Backbone.View<TModel, TOptions, TElement> {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vigor: any;
  viewTitleKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _uniqueId: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defer(callback: any): void;
}

export class View<
  // @ts-expect-error

  TModel extends TrelloModel = TrelloModel,
  TOptions extends ViewOptions = ViewOptions,
  TElement extends Element = HTMLElement,
> extends Backbone.View<TModel, TOptions, TElement> {
  constructor(options?: TOptions) {
    defaultRouter_DO_NOT_USE.updateSubscribers();
    super(...arguments);
  }
  get modelCache() {
    return ModelCache;
  }
  get _subviews() {
    // @ts-expect-error
    if (!this.subviews) {
      // @ts-expect-error
      this.subviews = {};
    }
    // @ts-expect-error
    return this.subviews;
  }
  set _subviews(subviews) {
    // @ts-expect-error
    this.subviews = subviews;
  }

  // @ts-expect-error
  get vigor() {
    // @ts-expect-error
    if (this._vigor === undefined) {
      // @ts-expect-error
      this._vigor = VIGOR.LOTS;
    }
    // @ts-expect-error
    return this._vigor;
  }
  // @ts-expect-error
  set vigor(value) {
    // @ts-expect-error
    this._vigor = value;
  }

  get _rendered() {
    // @ts-expect-error
    if (this.__rendered === undefined) {
      // @ts-expect-error
      this.__rendered = false;
    }
    // @ts-expect-error
    return this.__rendered;
  }
  set _rendered(value) {
    // @ts-expect-error
    this.__rendered = value;
  }

  render() {
    const args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    // @ts-expect-error
    if (this.renderOnce == null) {
      return this;
    }
    if (this._rendered) {
      switch (this.vigor) {
        case VIGOR.NONE:
          return this;
        case VIGOR.SOME:
          sendErrorEvent(
            Error('rendered more than once: ' + this.constructor.name),
          );
          return this;
        default:
          throw Error('You cannot render me more than once!');
      }
    }
    this._rendered = true;
    // @ts-expect-error
    // eslint-disable-next-line prefer-spread
    this.renderOnce.apply(this, args);
    return this;
  }

  renderIfNecessary() {
    // @ts-expect-error
    if (!this.renderOnce || !this._rendered) {
      this.render();
    }
    return this;
  }

  getViewTitle() {
    let ref;
    if (this.viewTitleKey == null) {
      return '';
    }
    const key = result(this, 'viewTitleKey');
    const args = (ref = result(this, 'viewTitleArguments')) != null ? ref : {};
    return l(['view title', key], args);
  }

  subview<T>(Type: T, model: unknown, opts?: unknown, key?: unknown) {
    if (opts == null) {
      opts = {};
    }
    if (key == null) {
      // @ts-expect-error
      key = Type.prototype.uniqueId() + '_' + model.cid;
    }
    // @ts-expect-error
    if (!(key in this._subviews)) {
      // @ts-expect-error
      this._subviews[key] = new Type({
        model,
        // @ts-expect-error
        ...opts,
      });
    }
    return this._subviews[key];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingSubview(Type: any, model: any, key: any) {
    if (key == null) {
      key = Type.prototype.uniqueId() + '_' + model.cid;
    }
    if (!(key in this._subviews)) {
      throw new Error('existingSubview called before subview was created');
    }
    return this._subviews[key];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingSubviewOrUndefined(Type: any, model: any, key: any) {
    if (key == null) {
      key = Type.prototype.uniqueId() + '_' + model.cid;
    }
    return this._subviews[key];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collectionSubview(Type: any, collection: any, opts: any, key?: any) {
    let base;
    if (opts == null) {
      opts = {};
    }
    if (key == null) {
      key = Type.prototype.uniqueId();
    }
    const subview =
      (base = this._subviews)[key] != null
        ? base[key]
        : (base[key] = new Type({
            collection,
            ...opts,
          }));
    if (opts.el != null && subview.el !== opts.el) {
      subview.setElement(opts.el);
    }
    return subview;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subviewsOfType(Type: any) {
    let key, subview;
    const ref = this._subviews;
    const results = [];
    for (key in ref) {
      if (!hasProp.call(ref, key)) continue;
      subview = ref[key];
      if (subview instanceof Type) {
        results.push(subview);
      }
    }
    return results;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteSubview(view: any, key?: any) {
    if (key == null) {
      key = view.constructor.prototype.uniqueId() + '_' + view.model.cid;
    }
    if (view !== this._subviews[key]) {
      throw new Error('incorrect key for subview in deleteSubview');
    }
    view.remove();
    delete this._subviews[key];
  }

  removeSubviews() {
    const subviews = values(this._subviews);
    this._subviews = {};
    SubviewRemover.enqueue(subviews);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deleteSubviewsOfType(Type: any) {
    let i, len, subview;
    const ref = this.subviewsOfType(Type);
    for (i = 0, len = ref.length; i < len; i++) {
      subview = ref[i];
      this.deleteSubview(subview);
    }
  }

  remove(...args: unknown[]): this | void {
    this.unsubscribe();
    this.stopUsing();
    this.removeSubviews();
    this.cancelScheduled();
    // @ts-expect-error
    return Backbone.View.prototype.remove.apply(this, arguments);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  frameDebounce(fn: any, context?: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let invoked: any;
    if (context == null) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      context = this;
    }
    invoked = false;
    return function () {
      if (invoked) {
        return;
      }
      invoked = true;
      // @ts-expect-error
      this.requestAnimationFrame(function () {
        invoked = false;
        fn.call(context);
      });
    };
  }

  makeDebouncedMethods(..._methodNames: string[]) {
    let i, len, methodName;
    const methodNames = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    for (i = 0, len = methodNames.length; i < len; i++) {
      methodName = methodNames[i];
      // @ts-expect-error
      this[methodName + 'Debounced'] = this.frameDebounce(this[methodName]);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delegateEvents(events?: any) {
    let key, view;
    const args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
    // @ts-expect-error
    Backbone.View.prototype.delegateEvents.apply(this, args);
    const ref = this._subviews;
    for (key in ref) {
      view = ref[key];
      view.delegateEvents();
    }
  }

  undelegateEvents() {
    let key, view;
    // @ts-expect-error
    Backbone.View.prototype.undelegateEvents.apply(this, arguments);
    const ref = this._subviews;
    for (key in ref) {
      view = ref[key];
      view.undelegateEvents();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendSubview(subview: any, $el?: JQuery<HTMLElement>) {
    return this.appendSubviews([subview], $el);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appendSubviews(subviews: any[], $el?: JQuery<HTMLElement>) {
    let i, len, subview;
    if ($el == null) {
      $el = this.$el;
    }
    const frag = document.createDocumentFragment();
    for (i = 0, len = subviews.length; i < len; i++) {
      subview = subviews[i];
      subview.delegateEvents();
      frag.appendChild(subview.render().el);
    }
    return $el.append(frag);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ensureSubview(subview: any, $el: JQuery<HTMLElement>) {
    return this.ensureSubviews([subview], $el);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ensureSubviews(subviews: any[], $el: JQuery<HTMLElement>) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let el: any,
      found,
      i,
      index,
      j,
      k,
      key,
      len,
      len1,
      len2,
      ref,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      subview: any,
      view;
    if ($el == null) {
      $el = this.$el;
    }
    if ($el.length < 1) {
      return;
    }
    const elContainer = $el[0];
    const elContainerNodes = clone(elContainer.childNodes);
    for (index = i = 0, len = elContainerNodes.length; i < len; index = ++i) {
      el = elContainerNodes[index];
      if (
        find(subviews, function (s) {
          return s.el === el;
        })
      ) {
        continue;
      }
      found = false;
      ref = this._subviews;
      for (key in ref) {
        view = ref[key];
        if (!(view.el === el && !found)) {
          continue;
        }
        this.deleteSubview(view, key);
        found = true;
      }
    }
    for (j = 0, len1 = subviews.length; j < len1; j++) {
      subview = subviews[j];
      if (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        find(elContainerNodes, function (el) {
          return subview.el === el;
        })
      ) {
        continue;
      }
      subview.delegateEvents();
      subview.render();
    }
    for (index = k = 0, len2 = subviews.length; k < len2; index = ++k) {
      subview = subviews[index];
      if (subview.el === elContainer.childNodes[index]) {
        continue;
      }
      if (index === 0) {
        $(elContainer).prepend(subview.el);
      } else {
        $(subviews[index - 1].el).after(subview.el);
      }
    }
  }

  uniqueId() {
    if (!Object.prototype.hasOwnProperty.call(this, '_uniqueId')) {
      this._uniqueId = uniqueId('view');
    }
    return this._uniqueId;
  }

  whenIdle(name: string, callback: () => View) {
    // Use the scheduler for the callback so it gets cancelled
    // if the view has been removed
    PostRender.enqueue(name, this.callback(callback));
  }

  // Scheduling mixin mappings
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTimeout(fn: any, interval: number) {
    return SchedulingMixin.setTimeout.call(this, fn, interval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInterval(fn: any, interval: number) {
    return SchedulingMixin.setInterval.call(this, fn, interval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestAnimationFrame(fn: any) {
    return SchedulingMixin.requestAnimationFrame.call(this, fn);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback(fn: any) {
    return SchedulingMixin.callback.call(this, fn);
  }
  cancelScheduled() {
    return SchedulingMixin.cancelScheduled.call(this);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defer(fn: any) {
    return SchedulingMixin.defer.call(this, fn);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debounce(fn: any, wait: any) {
    return SchedulingMixin.debounce.call(this, fn, wait);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dynamicDebounce(fn: any, getInterval: any) {
    return SchedulingMixin.dynamicDebounce.call(this, fn, getInterval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callOnceAfter(fn: any, wait: any) {
    return SchedulingMixin.callOnceAfter.call(this, fn, wait);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throttle(func: any, wait: any, options: any) {
    return SchedulingMixin.throttle.call(this, func, wait, options);
  }

  // Hearsay.mixin mapping
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribe(signal: any, callback: any) {
    return mixin.subscribe.call(this, signal, callback);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  using(signal: any) {
    return mixin.using.call(this, signal);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slot(val: any) {
    return mixin.slot.call(this, val);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribeChanges(signal: any, callbacks: any) {
    return mixin.subscribeChanges.call(this, signal, callbacks);
  }
  // Hearsay has an option with 3 params `watch(target, path, callback)`, but it is not used in Trello
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch(path: any, callback: any) {
    return mixin.watch.call(this, path, callback);
  }
  unsubscribe() {
    return mixin.unsubscribe.call(this);
  }
  stopUsing() {
    return mixin.stopUsing.call(this);
  }
}
