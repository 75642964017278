import { convertToPIIString } from '@trello/privacy';

import type { TrelloMember, TrelloMemberNonPublicData } from '../generated';

type TrelloMemberNodeFields = Pick<
  TrelloMember,
  'avatarUrl' | 'fullName' | 'initials'
> & {
  username: NonNullable<TrelloMember['username']>;
  objectId: NonNullable<TrelloMember['objectId']>;
  nonPublicData: Pick<
    TrelloMemberNonPublicData,
    'avatarUrl' | 'fullName' | 'initials'
  >;
};

type TrelloMembersData = {
  edges?: Array<{
    node?: TrelloMemberNodeFields | null;
  }>;
};

export const mapTrelloMemberDataToMember = (data: TrelloMembersData) => {
  return data.edges
    ?.map((edge) => {
      const memberNode = edge?.node;
      if (!memberNode) {
        return null;
      }
      return {
        id: memberNode.objectId,
        avatarUrl: memberNode.avatarUrl ?? null,
        fullName: convertToPIIString(memberNode.fullName) ?? null,
        initials: convertToPIIString(memberNode.initials) ?? null,
        nonPublic: {
          __typename: 'Member_NonPublic' as const,
          avatarUrl:
            convertToPIIString(memberNode.nonPublicData.avatarUrl) ?? null,
          fullName:
            convertToPIIString(memberNode.nonPublicData.fullName) ?? null,
          initials:
            convertToPIIString(memberNode.nonPublicData.initials) ?? null,
        },
        username: convertToPIIString(memberNode.username),
      };
    })
    .filter((edge) => edge !== null);
};
