import { useCallback, type FunctionComponent } from 'react';

import { LazyAtlassianAppSwitcherButton } from '@trello/atlassian-app-switcher';
import { isMemberLoggedIn } from '@trello/authentication';
import type { HeaderTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { LazyCreateWorkspaceViewPopoverScreenContent } from 'app/src/components/CreateWorkspaceViewPopoverScreen';
import { LazyInternalToolsButton } from 'app/src/components/InternalTools';
import { LazyInviteTeamMembersForm } from 'app/src/components/InviteTeamMembers';
import { LazyLoggedOutHeader } from 'app/src/components/LoggedOutHeader';
import { LazyNotificationsMenuButton } from 'app/src/components/NotificationsMenu';
import { LazyPlanComparisonModal } from 'app/src/components/PlanDetails/LazyPlanComparisonModal';
import { premiumTrialPaymentModalOverlayState } from 'app/src/components/PremiumTrialPaymentModalOverlay';
import { LazyTacoAnnouncements } from 'app/src/components/TacoAnnouncements';
import { LazyAuthenticatedHeader } from './LazyAuthenticatedHeader';
import { useShouldRenderHeader } from './useShouldRenderHeader';

import styles from './Header.less';

export const Header: FunctionComponent = () => {
  const shouldRenderHeader = useShouldRenderHeader();
  const onTrialExtensionClick = useCallback(() => {
    premiumTrialPaymentModalOverlayState.setValue({ isVisible: true });
  }, []);

  if (!shouldRenderHeader) {
    return null;
  }

  const isAuthenticated = isMemberLoggedIn();
  return (
    <div
      data-testid={getTestId<HeaderTestIds>('header-container')}
      data-desktop-id="header"
      // needed for hiding the header in madlibs and moonshot
      // create-first-board and welcome-to-trello
      data-js-id="header-container"
      className={isAuthenticated ? styles.header : styles.loggedOutHeader}
    >
      {isAuthenticated ? (
        <LazyAuthenticatedHeader
          AtlassianAppSwitcherButton={LazyAtlassianAppSwitcherButton}
          CreateWorkspaceViewPopover={
            LazyCreateWorkspaceViewPopoverScreenContent
          }
          InviteTeamMembersForm={LazyInviteTeamMembersForm}
          InternalToolsButton={LazyInternalToolsButton}
          NotificationsButton={LazyNotificationsMenuButton}
          TacoAnnouncements={LazyTacoAnnouncements}
          PlanComparisonModal={LazyPlanComparisonModal}
          onTrialExtensionClick={onTrialExtensionClick}
        />
      ) : (
        <LazyLoggedOutHeader />
      )}
    </div>
  );
};
