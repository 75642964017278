import type { InMemoryCache } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { sendErrorEvent } from '@trello/error-reporting';

import type { TrelloList } from '../generated';
import { isString } from '../plannerCardDataMapping/validateHelpers';
import type { RecursivePartial, TargetModel } from './cacheModelTypes';
import { getObjectIdFromCacheObject } from './getObjectIdFromCacheObject';
import { syncNativeToRestScalars } from './syncNativeToRestScalars';

/** Exported for testing only! */
export const fieldMappings = {
  name: { validate: isString },
};

/**
 * Given native TrelloList data, writes all list data to the List model
 * in the Apollo Cache
 * @param incoming A partial TrelloList model
 * @param cache The cache to write to
 */
export const syncTrelloListToList = (
  incoming: RecursivePartial<TrelloList>,
  cache: InMemoryCache,
  readField: ReadFieldFunction,
) => {
  try {
    const listId = getObjectIdFromCacheObject(incoming, readField);
    const list: TargetModel = { type: 'List', id: listId };
    syncNativeToRestScalars<TrelloList>(
      list,
      fieldMappings,
      incoming,
      cache,
      readField,
    );
  } catch (err) {
    sendErrorEvent(err);
  }
};
