import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloBoard,
  TrelloBoardBackground,
  TrelloBoardPrefs,
  TrelloEnterprise,
  TrelloWorkspace,
} from '../generated';

export const formatTrelloBoardData = (
  readField: ReadFieldFunction,
  boardRef: Readonly<TrelloBoard> | undefined,
  boardId: string,
) => {
  const trelloCardWorkspaceRef = readField<TrelloWorkspace>(
    'workspace',
    boardRef,
  );
  const trelloCardEnterpriseRef = readField<TrelloEnterprise>(
    'enterprise',
    boardRef,
  );
  const workspaceId = readField<string>('objectId', trelloCardWorkspaceRef);
  const enterpriseId = readField<string>('objectId', trelloCardEnterpriseRef);
  const name = readField<string>('name', boardRef);
  const url = readField<string>('url', boardRef);

  const boardPrefsRef = readField<TrelloBoardPrefs>('prefs', boardRef);
  const cardAging = readField<string>('cardAging', boardPrefsRef);
  const cardCovers = readField<boolean>('cardCovers', boardPrefsRef);
  const backgroundRef = readField<TrelloBoardBackground>(
    'background',
    boardPrefsRef,
  );
  const brightness = readField<string>('brightness', backgroundRef);
  const color = readField<string>('color', backgroundRef);
  const image = readField<string>('image', backgroundRef);
  const imageScaledRef = readField('imageScaled', backgroundRef);
  const topColor = readField<string>('topColor', backgroundRef);
  const showCompleteStatus = readField<boolean>(
    'showCompleteStatus',
    boardPrefsRef,
  );

  const imageScaled = Array.isArray(imageScaledRef)
    ? imageScaledRef.map((scaledImage) => ({
        height: scaledImage.height,
        url: scaledImage.url,
        width: scaledImage.width,
      }))
    : null;
  const powerUps = readField<TrelloBoard['powerUps']>('powerUps', boardRef);
  const formattedPowerUpEdges = powerUps?.edges
    ?.map((edge) => {
      const nodeRef = edge.node;
      const objectId = readField<string>('objectId', nodeRef);
      return {
        objectId: edge.objectId,
        node: { objectId },
      };
    })
    .filter((edge) => edge !== null);

  return {
    objectId: boardId,
    name,
    url,
    powerUps: { edges: formattedPowerUpEdges ?? [] },
    prefs:
      boardPrefsRef !== undefined
        ? {
            background: {
              brightness,
              color,
              image,
              imageScaled,
              topColor,
            },
            cardAging: cardAging ? cardAging : ('regular' as const),
            cardCovers,
            ...(showCompleteStatus !== undefined && { showCompleteStatus }),
          }
        : undefined,
    workspace:
      trelloCardWorkspaceRef !== undefined
        ? workspaceId
          ? { objectId: workspaceId }
          : null
        : undefined,
    enterprise:
      trelloCardEnterpriseRef !== undefined
        ? enterpriseId
          ? { objectId: enterpriseId }
          : null
        : undefined,
  };
};
