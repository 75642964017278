import { isBool, isNumber, isString, nullOrString } from './validateHelpers';

export type Cover = {
  brightness?: string | null;
  color?: string | null;
  size?: string | null;
  edgeColor?: string | null;
  sharedSourceUrl?: string | null;
  attachment?: { objectId?: string | null } | null;
  powerUp?: { objectId?: string | null } | null;
  uploadedBackground?: { objectId?: string | null } | null;
  previews?: {
    edges: Array<{
      node: {
        bytes: number;
        height: number;
        scaled: boolean;
        url: string;
        width: number;
      };
    }>;
  } | null;
};

export const validateTrelloCardCoverData = (
  cover: Cover | undefined,
): boolean => {
  if (cover === undefined) {
    return false;
  }
  return (
    nullOrString(cover.color) &&
    nullOrString(cover.size) &&
    ((cover.attachment && typeof cover.attachment.objectId === 'string') ||
      cover.attachment === null) &&
    ((cover.powerUp && typeof cover.powerUp.objectId === 'string') ||
      cover.powerUp === null) &&
    ((Array.isArray(cover.previews?.edges) &&
      cover.previews.edges.every((edge) => {
        const node = edge?.node;
        return (
          isNumber(node.bytes) &&
          isNumber(node.height) &&
          isBool(node.scaled) &&
          isString(node.url) &&
          isNumber(node.width)
        );
      })) ||
      cover.previews === null) &&
    ((cover.uploadedBackground &&
      typeof cover.uploadedBackground.objectId === 'string') ||
      cover.uploadedBackground === null)
  );
};
