// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l7zIXltDTw622E {
  grid-area: zip;
}
.y54mQqQtoA7mwA input[type='text'] {
  height: 40px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `l7zIXltDTw622E`,
	"input": `y54mQqQtoA7mwA`
};
export default ___CSS_LOADER_EXPORT___;
