import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloCardAttachmentsByType,
  TrelloCardAttachmentsCount,
  TrelloCardBadgeDueInfo,
  TrelloCardBadges,
  TrelloCardViewer,
} from '../generated';

export const formatTrelloCardBadgesData = (
  readField: ReadFieldFunction,
  cardBadgesRef: Readonly<TrelloCardBadges> | undefined,
) => {
  // basic fields
  const attachmentsBadge = readField<number>('attachments', cardBadgesRef);
  const checkItemsBadge = readField<number>('checkItems', cardBadgesRef);
  const checkItemsCheckedBadge = readField<number>(
    'checkItemsChecked',
    cardBadgesRef,
  );
  const checkItemsEarliestDueBadge = readField<string>(
    'checkItemsEarliestDue',
    cardBadgesRef,
  );
  const commentsBadge = readField<number>('comments', cardBadgesRef);
  const descriptionBadge = readField<boolean>('description', cardBadgesRef);
  const externalSourceBadge = readField<string>(
    'externalSource',
    cardBadgesRef,
  );
  const lastUpdatedByAiBadge = readField<boolean>(
    'lastUpdatedByAi',
    cardBadgesRef,
  );
  const locationBadge = readField<boolean>('location', cardBadgesRef);
  const maliciousAttachmentsBadge = readField<number>(
    'maliciousAttachments',
    cardBadgesRef,
  );
  const startedAtBadge = readField<string>('startedAt', cardBadgesRef);
  const votesBadge = readField<number>('votes', cardBadgesRef);

  // Construct attachmentsByType badge
  const attachmentsByTypeBadgeRef = readField<TrelloCardAttachmentsByType>(
    'attachmentsByType',
    cardBadgesRef,
  );
  const trelloAttachments = readField<TrelloCardAttachmentsCount>(
    'trello',
    attachmentsByTypeBadgeRef,
  );
  const trelloBoardAttachmentsCount = readField<number>(
    'board',
    trelloAttachments,
  );
  const trelloCardAttachmentsCount = readField<number>(
    'card',
    trelloAttachments,
  );

  const attachmentsByTypeBadge =
    trelloBoardAttachmentsCount !== undefined &&
    trelloCardAttachmentsCount !== undefined
      ? {
          trello: {
            board: trelloBoardAttachmentsCount,
            card: trelloCardAttachmentsCount,
          },
        }
      : undefined;

  // Construct due badge
  const dueBadgeRef = readField<TrelloCardBadgeDueInfo>('due', cardBadgesRef);
  const dueAt = readField<string>('at', dueBadgeRef);
  const dueComplete = readField<boolean>('complete', dueBadgeRef);
  const dueBadge =
    dueAt !== undefined && dueComplete !== undefined
      ? { at: dueAt, complete: dueComplete }
      : undefined;

  // Construct viewer badge
  const viewerBadgeRef = readField<TrelloCardViewer>('viewer', cardBadgesRef);
  const viewerSubscribed = readField<boolean>('subscribed', viewerBadgeRef);
  const viewerVoted = readField<boolean>('voted', viewerBadgeRef);
  const viewerBadge =
    viewerSubscribed !== undefined && viewerVoted !== undefined
      ? { subscribed: viewerSubscribed, voted: viewerVoted }
      : undefined;

  return {
    attachments: attachmentsBadge !== undefined ? attachmentsBadge : undefined,
    attachmentsByType: attachmentsByTypeBadge,
    checkItems: checkItemsBadge !== undefined ? checkItemsBadge : undefined,
    checkItemsChecked:
      checkItemsCheckedBadge !== undefined ? checkItemsCheckedBadge : undefined,
    checkItemsEarliestDue:
      checkItemsEarliestDueBadge !== undefined
        ? checkItemsEarliestDueBadge
        : undefined,
    comments: commentsBadge !== undefined ? commentsBadge : undefined,
    description: descriptionBadge !== undefined ? descriptionBadge : undefined,
    due: dueBadge,
    externalSource:
      externalSourceBadge !== undefined ? externalSourceBadge : undefined,
    lastUpdatedByAi:
      lastUpdatedByAiBadge !== undefined ? lastUpdatedByAiBadge : undefined,
    location: locationBadge !== undefined ? locationBadge : undefined,
    maliciousAttachments:
      maliciousAttachmentsBadge !== undefined
        ? maliciousAttachmentsBadge
        : undefined,
    startedAt: startedAtBadge !== undefined ? startedAtBadge : undefined,
    viewer: viewerBadge,
    votes: votesBadge !== undefined ? votesBadge : undefined,
  };
};
