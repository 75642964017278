import classNames from 'classnames';
import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import type { ColorBackground, GradientBackground } from '@trello/boards';
import { ColorBackgrounds, GradientBackgrounds } from '@trello/boards';
import { useSharedState } from '@trello/shared-state';

import type { BackgroundItemState } from 'app/src/components/CreateBoardPopover/createMenuState';
import { createMenuState } from 'app/src/components/CreateBoardPopover/createMenuState';
import { BackgroundPopoverItem } from './BackgroundPopoverItem';
import { getBoardBackgroundString } from './Helpers';

// eslint-disable-next-line @trello/less-matches-component
import styles from './BackgroundPickerPopover.less';

export const BackgroundPopoverColors: FunctionComponent = () => {
  const [menuState, setMenuState] = useSharedState(createMenuState);
  const background = menuState.background;
  const selectedItem = background.selected.id
    ? background.selected
    : background.preSelected;
  const setBackground = useCallback(
    (data: BackgroundItemState) => {
      return () => {
        setMenuState({
          ...menuState,
          background: {
            ...menuState.background,
            selected: data,
            shifted: data,
          },
        });
      };
    },
    [menuState, setMenuState],
  );

  const intl = useIntl();

  const renderColors = () => {
    return Object.entries(ColorBackgrounds).map(
      ([id, metadata]: [string, ColorBackground]) => {
        return (
          <BackgroundPopoverItem
            key={`color-${id}`}
            title={getBoardBackgroundString(id, intl)}
            color={metadata.color}
            selected={
              selectedItem.type === metadata.type && selectedItem.id === id
            }
            onSelect={setBackground({ type: 'default', id })}
          />
        );
      },
    );
  };

  const renderGradients = () => {
    return Object.entries(GradientBackgrounds).map(
      ([id, metadata]: [string, GradientBackground]) => {
        return (
          <BackgroundPopoverItem
            key={`gradient-${id}`}
            title={id}
            image={metadata.fullSizeUrl}
            color={metadata.color}
            selected={
              selectedItem.type === metadata.type && selectedItem.id === id
            }
            onSelect={setBackground({ type: 'gradient', id })}
          />
        );
      },
    );
  };

  return (
    <div
      className={classNames(
        styles.backgroundChooserColors,
        styles.backgroundGrid,
      )}
    >
      {renderGradients()}
      <hr />
      {renderColors()}
    </div>
  );
};
