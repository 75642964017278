import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type CardFrontMemberAvatarFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'id' | 'avatarUrl' | 'fullName' | 'initials' | 'username'>
  & { nonPublic?: Types.Maybe<(
    { __typename: 'Member_NonPublic' }
    & Pick<Types.Member_NonPublic, 'avatarUrl' | 'fullName' | 'initials'>
  )> }
);

export const CardFrontMemberAvatarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardFrontMemberAvatar' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'initials' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPublic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'initials' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseCardFrontMemberAvatarFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      CardFrontMemberAvatarFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseCardFrontMemberAvatarFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<CardFrontMemberAvatarFragment>,
    'data'
  > {
  data?: CardFrontMemberAvatarFragment;
}

export const useCardFrontMemberAvatarFragment = ({
  from,
  returnPartialData,
  ...options
}: UseCardFrontMemberAvatarFragmentOptions): UseCardFrontMemberAvatarFragmentResult => {
  const result = Apollo.useFragment<CardFrontMemberAvatarFragment>({
    ...options,
    fragment: CardFrontMemberAvatarFragmentDoc,
    fragmentName: 'CardFrontMemberAvatar',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as CardFrontMemberAvatarFragment };
};
