import classNames from 'classnames';
import type { ChangeEvent, FunctionComponent, ReactInstance } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';

import { useFeatureGate } from '@trello/feature-gate-client';
import { waitForImageLoad } from '@trello/image-previews';
import type { IterableType } from '@trello/infinite-list';
import { InfiniteList, LegacyInfiniteList } from '@trello/infinite-list';
import { SearchIcon } from '@trello/nachos/icons/search';
import { useSharedState } from '@trello/shared-state';
import type { Photo } from '@trello/unsplash';
import { unsplashClient } from '@trello/unsplash';

import { createMenuState } from 'app/src/components/CreateBoardPopover/createMenuState';
import { BackgroundPopoverItem } from './BackgroundPopoverItem';

// eslint-disable-next-line @trello/less-matches-component
import styles from './BackgroundPickerPopover.less';

/**
 * Breaks an array into chunks of a specific size
 *
 * chunk([1, 2, 3, 4, 5, 6, 7], 3) -> [[1, 2, 3], [4, 5, 6], [7]]
 */
function chunkPhotos(array: Photo[], size: number) {
  if (!array.length || size < 1) {
    return [];
  }
  const result = new Array(Math.ceil(array.length / size));
  for (let idx = 0; idx < result.length; idx++) {
    result[idx] = array.slice(idx * size, idx * size + size);
  }

  return result;
}

const loadMorePhotos = (query = '', page = 1) => {
  // Either use the search API or the latest top picks based
  // on whether a query was provided

  // Do not assume a trimmed string is being provided
  const sanitizedQuery = query.trim();

  const unsplashPromise = sanitizedQuery
    ? unsplashClient.search({ query: sanitizedQuery, page })
    : unsplashClient.getDefaultCollection({ page });

  return unsplashPromise.then(({ response }) => {
    const photos: Photo[] = response?.results || [];
    const total = response?.total || 0;

    return Promise.all(
      photos.map((photo) => waitForImageLoad(photo.urls.small)),
    ).then(() => ({ photos, total }));
  });
};

export const BackgroundPopoverPhotos: FunctionComponent = () => {
  const [currentPhotosPage, setCurrentPhotosPage] = useState(0);
  const [hasMorePhotos, setHasMorePhotos] = useState(true);
  const [menuState, setMenuState] = useSharedState(createMenuState);
  const { currentPhotosQuery, photos, isLoadingPhotos, background } = menuState;
  const selectedItem = background.selected.id
    ? background.selected
    : background.preSelected;
  const setBackground = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      return () => {
        setMenuState({
          ...menuState,
          background: {
            ...menuState.background,
            selected: data,
            shifted: data,
          },
        });
      };
    },
    [menuState, setMenuState],
  );
  const loadMore = useCallback(
    async (query: string) => {
      setMenuState((state) => ({
        ...state,
        isLoadingPhotos: true,
      }));

      try {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { photos, total } = await loadMorePhotos(
          query,
          currentPhotosPage + 1,
        );
        setHasMorePhotos(menuState.photos.length < total);

        // filter out photos which might already have loaded
        const newPhotos = (photos || []).filter(
          (photo) =>
            !menuState.photos.find((existing) => existing.id === photo.id),
        );
        const combinedPhotos = [...menuState.photos, ...newPhotos];
        setMenuState((state) => ({
          ...state,
          photos: combinedPhotos,
          isLoadingPhotos: false,
        }));
      } catch (err) {
        setMenuState({
          ...menuState,
          photos: [],
          isLoadingPhotos: false,
        });
      }
      setCurrentPhotosPage(currentPhotosPage + 1);
    },
    [menuState, setMenuState, currentPhotosPage],
  );
  const debouncedLoadMore = useDebouncedCallback(loadMore, 500);

  const [rows, setRows] = useState<Photo[][]>([]);
  const [query, setQuery] = useState(currentPhotosQuery);

  useEffect(() => {
    // If we are loading more photos, but we don't have enough
    // to populate a full row, we want to trim extraneous photos
    // so we can maintain 3 items per row, until the final page
    // of results come in (which may be less than 3)
    const photosToTrim = isLoadingPhotos ? photos.length % 2 : 0;
    const photosToKeep = photos.slice(0, photos.length - photosToTrim);
    setRows(chunkPhotos(photosToKeep, 2));
  }, [photos, isLoadingPhotos]);

  const onQueryChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const query = e.target.value;
      setQuery(query);
      //Reset the list of photos before loading more
      const { selected, preSelected } = menuState.background;
      const photosToKeep = menuState.photos.filter(
        (photo) =>
          photo.id === selected.id ||
          (photo.id === preSelected.id && selected.id === null),
      );
      setMenuState((state) => ({
        ...state,
        photos: photosToKeep,
        currentPhotosQuery: query,
        isLoadingPhotos: true,
      }));
      setCurrentPhotosPage(0);
      debouncedLoadMore(query);
    },
    [menuState.background, menuState.photos, setMenuState, debouncedLoadMore],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getPhotoComponents = (photos: Photo[]) => {
    return photos.map(({ id, urls, user }: Photo) => {
      return (
        <BackgroundPopoverItem
          key={`unsplash-${id}`}
          image={urls.small}
          user={user}
          selected={selectedItem.type === 'unsplash' && selectedItem.id === id}
          onSelect={setBackground({ type: 'unsplash', id })}
          isPhoto
        />
      );
    });
  };

  const getPlaceholderImages = (row: number) => {
    return [1, 2, 3].map((item) => (
      <div
        key={`placeholder-${row}-${item}`}
        className={styles.backgroundGridItem}
      >
        <div className={classNames(styles.backgroundGridTrigger)} />
      </div>
    ));
  };

  const renderVirtualizedContent = (index: number, key: number) => {
    return (
      <div className={styles.itemRow} key={key}>
        {rows.length
          ? getPhotoComponents(rows[index])
          : getPlaceholderImages(index)}
      </div>
    );
  };

  const itemsRenderer = useCallback(
    (items: IterableType, ref: (instance: ReactInstance) => void) => {
      // @ts-expect-error

      return <div ref={(div: HTMLDivElement) => ref(div)}>{items}</div>;
    },
    [],
  );

  const intl = useIntl();

  const { value: shouldUseNewInfiniteList } = useFeatureGate(
    'tplat_new_infinite_list',
  );

  return (
    <div className={styles.photosWrapper}>
      <div className={styles.searchWrapper}>
        <SearchIcon
          size="small"
          block
          dangerous_className={styles.searchIcon}
        />
        <input
          autoFocus
          className={styles.searchInput}
          placeholder={intl.formatMessage({
            id: 'background photos.photos',
            defaultMessage: 'Photos',
            description:
              'Placeholder text for the search input in the background picker',
          })}
          value={query}
          onChange={onQueryChanged}
        />
      </div>
      {shouldUseNewInfiniteList ? (
        <InfiniteList
          loadMore={() => loadMore(query)}
          hasMore={!isLoadingPhotos && hasMorePhotos}
          isLoading={isLoadingPhotos}
        >
          {rows.map((_, index) => renderVirtualizedContent(index, index))}
        </InfiniteList>
      ) : (
        <LegacyInfiniteList
          itemCount={rows.length}
          pageSize={rows.length}
          awaitMore={!isLoadingPhotos && hasMorePhotos}
          renderItem={(index: number, key: number) => {
            return renderVirtualizedContent(index, key);
          }}
          loadMore={() => loadMore(query)}
          isLoading={isLoadingPhotos}
          itemsRenderer={itemsRenderer}
          threshold="30%"
          axis="y"
        />
      )}
      {!hasMorePhotos && !isLoadingPhotos && rows.length === 0
        ? intl.formatMessage({
            id: 'background photos.no results',
            defaultMessage:
              "Sorry, your search didn't return any results. Please try again!",
            description:
              'Message shown when a user searches for photos and no results are found',
          })
        : null}
    </div>
  );
};
