import { useEffect } from 'react';

import { getMemberId } from '@trello/authentication';
import { Entitlements } from '@trello/entitlements';
import { deepEqual } from '@trello/objects';
import { PersistentSharedState, useSharedState } from '@trello/shared-state';

import type { MemberEnterpriseDataFragment } from './MemberEnterprisesDataFragment.generated';
import { useMemberEnterpriseDataFragment } from './MemberEnterprisesDataFragment.generated';

export interface MemberEnterpriseDataSharedState {
  idEnterprise: string | undefined;
  idEnterprises: string[];
  inEnterprise: boolean | undefined;
  inRealEnterprise: boolean | undefined;
}

const enterpriseDataSharedState: MemberEnterpriseDataSharedState = {
  idEnterprise: undefined,
  idEnterprises: [],
  inEnterprise: undefined,
  inRealEnterprise: undefined,
};

const makeEnterpriseData = (
  memberData: MemberEnterpriseDataFragment | undefined,
): MemberEnterpriseDataSharedState => {
  return {
    idEnterprise: memberData?.idEnterprise || undefined,
    idEnterprises: memberData?.enterprises?.map(({ id }) => id) || [],
    inEnterprise: (memberData?.enterprises || []).length > 0,
    inRealEnterprise: memberData?.enterprises.some((enterprise) =>
      Entitlements.isEnterprise(enterprise.offering),
    ),
  };
};

// We version the key so that stale web versions do not try to write values
// with an old data shape to local storage.
// Please update this value when MemberEnterpriseDataSharedState type definition changes.
const version = '47e422f423c1d4c9debfc325b726ee6665df2a10';

const memberIdEnterprisesSharedState =
  new PersistentSharedState<MemberEnterpriseDataSharedState>(
    enterpriseDataSharedState,
    {
      storageKey: () =>
        `memberEnterpriseData-${getMemberId() || 'anonymous'}-${version}`,
    },
  );

export const useCachedMemberEnterpriseData: (
  trelloMemberId: string,
) => MemberEnterpriseDataSharedState = (trelloMemberId) => {
  const { data: memberData, complete: isMemberDataLoaded } =
    useMemberEnterpriseDataFragment({
      from: { id: trelloMemberId },
    });

  const [enterprisesState, setMemberEnterpriseData] = useSharedState(
    memberIdEnterprisesSharedState,
  );

  useEffect(() => {
    if (!isMemberDataLoaded) {
      return;
    }

    const fetchedEnterpriseData = makeEnterpriseData(memberData);
    if (!deepEqual(enterprisesState, fetchedEnterpriseData)) {
      setMemberEnterpriseData(fetchedEnterpriseData);
    }
  }, [
    enterprisesState,
    isMemberDataLoaded,
    memberData,
    setMemberEnterpriseData,
  ]);

  if (!isMemberDataLoaded) {
    return enterprisesState;
  }

  return makeEnterpriseData(memberData);
};
