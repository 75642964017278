import type {
  ComponentProps,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { TryBetaSpotlight } from './TryBetaSpotlight';

export const LazyTryBetaSpotlight: FunctionComponent<
  PropsWithChildren<ComponentProps<typeof TryBetaSpotlight>>
> = (props) => {
  const TryBetaSpotlight = useLazyComponent(
    () =>
      import(/* webpackChunkName: "try-beta-spotlight" */ './TryBetaSpotlight'),
    { namedImport: 'TryBetaSpotlight', preload: false },
  );

  return (
    <Suspense fallback={props.children}>
      <ChunkLoadErrorBoundary fallback={null}>
        <TryBetaSpotlight {...props} />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};
