import { isBool, isNumber, isString, nullOrString } from './validateHelpers';

export type CustomFieldItems = {
  edges: Array<{
    node: {
      customField?: {
        display?: {
          cardFront?: boolean | null;
        } | null;
        name?: string | null;
        objectId?: string | null;
        options?: Array<{
          color?: string | null;
          objectId?: string | null;
          position?: number | null;
          value?: {
            text?: string | null;
          } | null;
        }> | null;
        position?: number | null;
        type?: string | null;
      };
      model?: {
        objectId?: string | null;
      } | null;
      objectId?: string | null;
      value?: {
        checked?: boolean | null;
        date?: string | null;
        id?: string | null;
        number?: number | null;
        text?: string | null;
      } | null;
    } | null;
  }> | null;
} | null;

export const validateTrelloCardCustomFieldItemsData = (
  customFieldItems: CustomFieldItems | undefined,
): boolean => {
  if (customFieldItems === undefined) {
    return false;
  }
  return (
    Array.isArray(customFieldItems?.edges) &&
    customFieldItems.edges.every((edge) => {
      const node = edge?.node;
      if (node?.objectId === undefined) {
        return false;
      }

      return (
        isBool(node.customField?.display?.cardFront) &&
        isString(node.customField?.name) &&
        isString(node.customField?.objectId) &&
        Array.isArray(node.customField?.options) &&
        node.customField.options.every((option) => {
          return (
            isString(option.color) &&
            isString(option.objectId) &&
            isNumber(option.position) &&
            isString(option.value?.text)
          );
        }) &&
        isNumber(node.customField?.position) &&
        isString(node.customField?.type) &&
        ['checkbox', 'date', 'list', 'number', 'text'].includes(
          node.customField.type,
        ) &&
        isString(node.model?.objectId) &&
        nullOrString(node.objectId) &&
        (isString(node?.value?.id) ||
          (node?.value?.id === null &&
            (typeof node?.value?.checked === 'boolean' ||
              node?.value?.checked === null) &&
            (typeof node?.value?.date === 'string' ||
              node?.value?.date === null) &&
            (typeof node?.value?.number === 'number' ||
              node?.value?.number === null) &&
            (typeof node?.value?.text === 'string' ||
              node?.value?.text === null)))
      );
    })
  );
};
