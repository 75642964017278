// Do not edit directly. Generated with `npm run -w @trello/nachos build-tokens`

export const ButtonDefaultPaddingX: string = '12px';
export const ButtonDefaultPaddingY: string = '6px';
export const ButtonDefaultBorderRadius: string = '3px';
export const ButtonDefaultBackgroundColor: string = 'rgba(9,30,66, 4%)';
export const ButtonDefaultHoverBackgroundColor: string = 'rgba(9,30,66, 8%)';
export const ButtonDefaultActiveColor: string = '#0079BF';
export const ButtonDefaultActiveBackgroundColor: string = '#E4F0F6';
export const ButtonDefaultDisabledColor: string = '#A5ADBA';
export const ButtonDefaultDisabledBackgroundColor: string = 'rgba(9,30,66, 4%)';
export const ButtonDefaultSelectedColor: string = '#FFFFFF';
export const ButtonDefaultSelectedBackgroundColor: string = '#172B4D';
export const ButtonPrimaryBackgroundColor: string = '#0079BF';
export const ButtonPrimaryColor: string = '#FFFFFF';
export const ButtonPrimaryHoverBackgroundColor: string = '#026AA7';
export const ButtonPrimaryHoverColor: string = '#FFFFFF';
export const ButtonPrimaryActiveBackgroundColor: string = '#055A8C';
export const ButtonPrimaryActiveColor: string = '#FFFFFF';
export const ButtonDangerBackgroundColor: string = '#B04632';
export const ButtonDangerColor: string = '#FFFFFF';
export const ButtonDangerHoverBackgroundColor: string = '#933B27';
export const ButtonDangerHoverColor: string = '#FFFFFF';
export const ButtonDangerActiveBackgroundColor: string = '#6E2F1A';
export const ButtonDangerActiveColor: string = '#FFFFFF';
export const ButtonSubtleBackgroundColor: string = 'rgba(255, 255, 255, 0.3)';
export const ButtonSubtleColor: string = '#FFFFFF';
export const ButtonSubtleHoverBackgroundColor: string =
  'rgba(255, 255, 255, 0.2)';
export const ButtonSubtleHoverColor: string = '#FFFFFF';
export const ButtonSubtleActiveBackgroundColor: string =
  'rgba(255, 255, 255, 0.1)';
export const ButtonSubtleActiveColor: string = '#FFFFFF';
export const CheckboxDefaultHeight: string = '16px';
export const CheckboxDefaultBorderRadius: string = '2px';
export const CheckboxDefaultStrokeWidth: string = '1px';
export const CheckboxDefaultWidth: string = '16px';
export const CheckboxIconDefaultBorderColor: string = '#DFE1E6';
export const CheckboxIconDefaultBackgroundColor: string = '#FAFBFC';
export const CheckboxIconDefaultColor: string = 'transparent';
export const CheckboxIconDefaultHoverBorderColor: string = '#DFE1E6';
export const CheckboxIconDefaultHoverBackgroundColor: string = '#EBECF0';
export const CheckboxIconDefaultActiveBorderColor: string = '#E4F0F6';
export const CheckboxIconDefaultActiveBackgroundColor: string = '#E4F0F6';
export const CheckboxIconDefaultActiveAndCheckedColor: string = '#0079BF';
export const CheckboxIconDefaultDisabledBorderColor: string = '#F4F5F7';
export const CheckboxIconDefaultDisabledBackgroundColor: string = '#F4F5F7';
export const CheckboxIconDefaultDisabledColor: string = '#A5ADBA';
export const CheckboxIconDefaultCheckedBorderColor: string = '#0079BF';
export const CheckboxIconDefaultCheckedBackgroundColor: string = '#0079BF';
export const CheckboxIconDefaultCheckedColor: string = '#FFFFFF';
export const CheckboxIconDefaultHoveredAndCheckedBorderColor: string =
  '#5BA4CF';
export const CheckboxIconDefaultHoveredAndCheckedBackgroundColor: string =
  '#5BA4CF';
export const CheckboxIconDefaultFocusBorderColor: string = '#0079BF';
export const CheckboxIconDefaultInvalidBorderColor: string = '#EB5A46';
export const CheckboxLabelDefaultPaddingX: string = '8px';
export const CheckboxLabelDefaultPaddingY: string = '6px';
export const CheckboxClassnameBase = 'checkbox' as const;
export const ComponentAppearanceCompact = 'compact' as const;
export const ComponentAppearanceDefault = 'default' as const;
export const ComponentAppearanceDanger = 'danger' as const;
export const ComponentAppearancePrimary = 'primary' as const;
export const ComponentAppearanceStatic = 'static' as const;
export const ComponentAppearanceSubtle = 'subtle' as const;
export const ComponentAppearanceTransparent = 'transparent' as const;
export const ComponentSizeL = 'large' as const;
export const ComponentSizeM = 'medium' as const;
export const ComponentSizeS = 'small' as const;
export const ComponentSizeXl = 'xlarge' as const;
export const ComponentSizeXs = 'xsmall' as const;
export const ComponentStateActive = 'active' as const;
export const ComponentStateChecked = 'checked' as const;
export const ComponentStateDisabled = 'disabled' as const;
export const ComponentStateFocus = 'focus' as const;
export const ComponentStateHover = 'hover' as const;
export const ComponentStateIdle = 'idle' as const;
export const ComponentStateIndeterminate = 'indeterminate' as const;
export const ComponentStateInvalid = 'invalid' as const;
export const ComponentStateSelected = 'selected' as const;
export const DropdownClassnameBase = 'dropdown' as const;
export const DropdownClassnameContent = 'content' as const; // wrapper around the content rendered in dropdown if no items are provided
export const DropdownClassnameFitted = 'fitted' as const; // fixes the container to the width of the content. This should be used primarily for text items.
export const DropdownDefaultBackgroundColor: string = '#FFFFFF';
export const DropdownDefaultBorderRadius: string = '3px';
export const DropdownDefaultPadding: string = '4px 0';
export const DropdownDefaultWidth: string = '256px';
export const DropdownContentDefaultPadding: string = '4px 12px'; // padding for dropdown if no items are provided
export const DropdownItemDefaultPaddingX: string = '12px';
export const DropdownItemDefaultPaddingY: string = '6px';
export const DropdownItemDefaultHoverBackgroundColor: string =
  'rgba(9,30,66, 4%)';
export const DropdownItemDefaultActiveBackgroundColor: string = '#E4F0F6';
export const IconClassnameBase = 'icon' as const;
export const IconClassnameDark = 'dark' as const;
export const IconClassnameLight = 'light' as const;
export const IconClassnameQuiet = 'quiet' as const;
export const IconDefaultColor: string = '#42526E';
export const IconDefaultHoverColor: string = '#FFFFFF';
export const IconLightColor: string = '#FFFFFF';
export const IconQuietColor: string = '#6B778C';
export const IconDarkColor: string = '#172B4D';
export const IconDisabledColor: string = '#A5ADBA';
export const IconRedColor: string = '#EB5A46';
export const IconGreenColor: string = '#61BD4F';
export const IconYellowColor: string = '#F2D600';
export const IconBlueColor: string = '#0079BF';
export const IconPurpleColor: string = '#C377E0';
export const IconPinkColor: string = '#FF78CB';
export const IconXsmallHeight: string = '14px';
export const IconXsmallWidth: string = '14px';
export const IconXsmallLineHeight: string = '14px';
export const IconSmallHeight: string = '16px';
export const IconSmallWidth: string = '16px';
export const IconSmallLineHeight: string = '16px';
export const IconMediumHeight: string = '20px';
export const IconMediumWidth: string = '20px';
export const IconMediumLineHeight: string = '20px';
export const IconLargeHeight: string = '24px';
export const IconLargeWidth: string = '24px';
export const IconLargeLineHeight: string = '24px';
export const IconBlockHeight: string = '32px';
export const IconBlockWidth: string = '32px';
export const IconBlockSmallHeight: string = '20px';
export const IconBlockSmallWidth: string = '20px';
export const ListClassnameBase = 'list' as const;
export const ListClassnameCell = 'cell' as const; // This is similar to a list item but can contain more complex elements like an image, meta text, etc.
export const ListClassnameContent = 'content' as const;
export const ListClassnameImage = 'image' as const;
export const ListClassnameItem = 'item' as const;
export const ListClassnameLabel = 'label' as const;
export const ListClassnameMeta = 'meta' as const;
export const ListItemDefaultPaddingX: string = '12px';
export const ListItemDefaultPaddingY: string = '6px';
export const PopoverClassnameBase = 'popover' as const;
export const PopoverClassnameContent = 'content' as const; // main body of the popover
export const PopoverClassnameHeader = 'header' as const; // contains popover title (if provided), back button (if needed), close button
export const PopoverClassnameTitle = 'title' as const; // contains popover title, if provided
export const PopoverClassnameIconButton = 'iconButton' as const;
export const PopoverClassnameBackButton = 'backButton' as const;
export const PopoverClassnameCloseButton = 'closeButton' as const;
export const PopoverDefaultPaddingX: string = '12px';
export const PopoverDefaultBackgroundColor: string = '#FFFFFF';
export const PopoverDefaultBorderRadius: string = '8px';
export const PopoverSmallWidth: string = '232px';
export const PopoverMediumWidth: string = '304px';
export const PopoverLargeWidth: string = '384px';
export const PopoverXlargeWidth: string = '456px';
export const PopoverPortalDefaultZIndex: number = 1;
export const PopoverPopperDefaultPaddingUnit: number = 4;
export const PopoverHeaderDefaultMarginBottom: string = '8px';
export const PopoverHeaderDefaultPaddingY: string = '0';
export const PopoverHeaderDefaultPaddingX: string = '32px';
export const PopoverTitleDefaultHeight: string = '40px';
export const PopoverTitleDefaultMargin: string = '0';
export const PopoverTitleDefaultPaddingY: string = '0';
export const PopoverTitleDefaultPaddingX: string = '32px';
export const PopoverContentDefaultPadding: string = '12px';
export const PopoverIconButtonDefaultZIndex: string = '2';
export const PopoverSectionClassnameAnimating =
  'popover-section-animating' as const;
export const SelectClassnameBase = 'select' as const;
export const SelectClassnameContainer = 'container' as const;
export const SelectClassnameControl = 'control' as const;
export const SelectClassnameMenu = 'menu' as const;
export const SelectClassnameOption = 'option' as const;
export const SelectClassnameLabel = 'label' as const;
export const SelectClassnameVal = 'val' as const; // naming this 'value' messes with style-dictionary compiling the other classname values
export const SelectControlDefaultBackgroundColor: string = '#FAFBFC';
export const SelectControlDefaultBorder: string = 'none';
export const SelectControlDefaultBorderRadius: string = '3px';
export const SelectControlDefaultBoxShadow: string = 'inset 0 0 0 1px #DFE1E6';
export const SelectControlDefaultFocusBackgroundColor: string = '#FFFFFF';
export const SelectControlDefaultFocusBoxShadow: string =
  'inset 0 0 0 2px #0079BF';
export const SelectControlDefaultHeight: string = '36px';
export const SelectControlDefaultHoverBackgroundColor: string = '#EBECF0';
export const SelectControlDefaultPaddingX: string = '12px';
export const SelectControlDefaultPaddingY: string = '8px';
export const SelectControlDefaultWidth: string = '100%';
export const SelectControlCompactHeight: string = '24px';
export const SelectControlCompactPaddingX: string = '8px';
export const SelectControlCompactPaddingY: string = '2px';
export const SelectControlSubtleBackgroundColor: string = '#F4F5F7';
export const SelectControlSubtleBoxShadow: string = 'none';
export const SelectGroupHeadingDefaultColor: string = '#5E6C84';
export const SelectGroupHeadingDefaultFontSize: string = '12px';
export const SelectGroupHeadingDefaultFontWeight: string = '500';
export const SelectGroupHeadingDefaultLineHeight: string = '16px';
export const SelectGroupHeadingDefaultMarginBottom: string = '4px';
export const SelectInputDefaultLeft: string = '12px';
export const SelectInputDefaultTop: string = '8px';
export const SelectInputDefaultMaxWidth: string = 'calc(100% - 48px)'; // Since we want to truncate the names of longer input values, we need to limit the width of the value before it hits the caret icon (48px is from font-size plus paddingX)
export const SelectInputCompactLeft: string = '8px';
export const SelectInputCompactTop: string = '2px';
export const SelectOptionDefaultActiveBackgroundColor: string = '#E4F0F6';
export const SelectOptionDefaultDisabledColor: string = '#A5ADBA';
export const SelectOptionDefaultDisabledBackgroundColor: string =
  'rgba(9,30,66, 4%)';
export const SelectOptionDefaultSelectedBackgroundColor: string = '#172B4D';
export const SelectOptionDefaultSelectedColor: string = '#FFFFFF';
export const SelectSingleValueDefaultFontSize: string = '16px';
export const SelectSingleValueDefaultFontWeight: string = '700';
export const SelectSingleValueDefaultLineHeight: string = '20px';
export const SelectSingleValueDefaultMarginLeft: string = '0';
export const SelectValueContainerDefaultMaxWidth: string = 'calc(100% - 20px)'; // Since we want to truncate the names of longer options, we need to limit the width of the value before it hits the caret icon (the caret icon is 20px)
export const SelectValueContainerDefaultPadding: string = '0';
export const SelectValueContainerDefaultLoadingMaxWidth: string =
  'calc(100% - 56px)'; // Truncate the option before the loading indicator (56px comes from 20px caret + 20px loading indicator + 16px padding in between)
export const SelectIndicatorsContainerDefaultPaddingRight: string = '16px'; // Push loading indicators inside the caret
export const TextfieldClassnameBase = 'textfield' as const;
export const TextfieldDefaultBackgroundColor: string = '#FAFBFC';
export const TextfieldDefaultBorderRadius: string = '3px';
export const TextfieldDefaultBoxShadowColor: string = '#DFE1E6';
export const TextfieldDefaultBoxShadowWidth: string = '1px';
export const TextfieldDefaultColor: string = '#172B4D';
export const TextfieldDefaultDisabledBackgroundColor: string = '#EBECF0';
export const TextfieldDefaultDisabledColor: string = '#A5ADBA';
export const TextfieldDefaultFocusBackgroundColor: string = '#FFFFFF';
export const TextfieldDefaultFocusBoxShadowColor: string = '#0079BF';
export const TextfieldDefaultFocusBoxShadowWidth: string = '2px';
export const TextfieldDefaultHoverBackgroundColor: string = '#EBECF0';
export const TextfieldDefaultHoverBoxShadowColor: string = '#DFE1E6';
export const TextfieldDefaultInvalidBoxShadowColor: string = '#EB5A46';
export const TextfieldDefaultReadonlyBackgroundColor: string = '#FAFBFC';
export const TextfieldDefaultReadonlyBoxShadowColor: string = '#EBECF0';
export const TextfieldDefaultReadonlyBoxShadowWidth: string = '2px';
export const TextfieldDefaultPaddingX: string = '12px';
export const TextfieldDefaultPaddingY: string = '8px';
export const TextfieldLabelDefaultFontSize: string = '12px';
export const TextfieldLabelDefaultLineHeight: string = '16px';
export const TextfieldLabelDefaultColor: string = '#5E6C84';
export const TextfieldLabelDefaultFontWeight: string = '700';
export const TextfieldLabelDefaultRequiredMarkerColor: string = '#EB5A46';
export const TextfieldSubtleBackgroundColor: string = 'transparent';
export const TextfieldSubtleBoxShadowColor: string = 'none';
export const TextfieldSubtleColor: string = '#FFFFFF';
export const TextfieldSubtleHoverBackgroundColor: string =
  'rgba(255, 255, 255, 0.2)';
export const TextfieldSubtleFocusBackgroundColor: string =
  'rgba(255, 255, 255, 0.3)';
export const TextfieldTransparentBackgroundColor: string = 'transparent';
export const TextfieldTransparentBoxShadowColor: string = 'none';
export const TextfieldTransparentHoverBackgroundColor: string = 'transparent';
export const TextfieldTransparentFocusBackgroundColor: string = 'transparent';
export const TextfieldTransparentDisabledBackgroundColor: string =
  'transparent';
export const TextfieldTransparentDisabledColor: string = '#A5ADBA';
export const TextfieldCompactHeight: string = '24px';
export const TextfieldCompactLineHeight: string = '24px';
export const TextfieldCompactPadding: string = '4px';
export const TextfieldBorderlessBackgroundColor: string = '#F4F5F7';
export const TextfieldBorderlessBoxShadow: string = 'none';
export const TextfieldPlaceholderDefaultColor: string = '#6B778C';
export const TextfieldPlaceholderSubtleColor: string =
  'rgba(255, 255, 255, 0.6)';
export const CellDefaultActiveBackgroundColor: string = '#E4F0F6';
export const CellDefaultHoverBackgroundColor: string = 'rgba(9,30,66, 4%)';
export const CellDefaultMinHeight: string = '48px';
export const CellContentDefaultPaddingX: string = '12px';
export const CellContentDefaultPaddingY: string = '6px';
export const CellImageDefaultMargin: string = 'auto 0 auto auto'; // Let css-grid take care of the container and margin: auto take care of alignment. Margin-right 0 is to make up for the difference of the content padding.
export const CellImageDefaultWidth: string = '32px';
export const AvatarDefaultColor: string = '#DFE1E6';
export const AvatarDefaultHoverColor: string = '#C1C7D0';
export const AvatarDefaultActiveColor: string = '#B3BAC5';
export const DialogDefaultBackgroundColor: string = '#F4F5F7';
export const DividerDefaultColor: string = 'rgba(9,30,66, 13%)';
export const GLOBAL_NAMESPACE_PREFIX: string = 'nch-';
export const GLOBAL_NATIVE_FONT: string =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif';
export const GlobalDefaultBorderRadius: string = '3px';
export const ScrollbarDefaultHeight: string = '8px';
export const ScrollbarDefaultWidth: string = '8px';
export const ScrollbarDefaultTrackPieceBackground: string = 'rgba(9,30,66, 8%)';
export const ScrollbarDefaultTrackPieceBorderRadius: string = '4px';
export const ScrollbarDefaultThumbBackground: string = 'rgba(9,30,66, 13%)';
export const ScrollbarDefaultThumbBorderRadius: string = '4px';
export const ScrollbarDefaultThumbHeight: string = '48px';
export const Spacing0: string = '0';
export const Spacing1: string = '2px';
export const Spacing2: string = '4px';
export const Spacing3: string = '6px';
export const Spacing4: string = '8px';
export const Spacing5: string = '16px';
export const Spacing6: string = '24px';
export const Spacing7: string = '32px';
export const Spacing8: string = '40px';
export const SpacingDefault: string = '8px';
export const TextDefaultColor: string = '#172B4D';
export const TextDefaultFontWeight: string = '400';
export const TextDefaultFontSize: string = '14px';
export const TextDefaultLineHeight: string = '20px';
export const TextBoldFontWeight: string = '700';
export const TextLightColor: string = '#FFFFFF';
export const TextQuietColor: string = '#5E6C84';
export const TextDisabledColor: string = '#A5ADBA';
export const TextDarkColor: string = '#091E42';
export const Z0: string = 'none';
export const Z100: string =
  '0 1px 1px rgba(9,30,66, 25%), 0 0 0 1px rgba(9,30,66, 8%)';
export const Z200: string =
  '0 4px 8px -2px rgba(9,30,66, 25%), 0 0 0 1px rgba(9,30,66, 8%)';
export const Z300: string =
  '0 8px 16px -4px rgba(9,30,66, 25%), 0 0 0 1px rgba(9,30,66, 8%)';
export const Z400: string =
  '0 12px 24px -6px rgba(9,30,66, 25%), 0 0 0 1px rgba(9,30,66, 8%)';
export const Z500: string =
  '0 20px 32px -8px rgba(9,30,66, 25%), 0 0 0 1px rgba(9,30,66, 8%)';
export const ZBorder: string = '0 0 0 1px rgba(9,30,66, 8%)';
