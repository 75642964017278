export type MemberData = {
  edges: Array<{
    node: {
      objectId?: string;
      avatarUrl?: string | null;
      fullName?: string | null;
      initials?: string | null;
      nonPublicData?: {
        avatarUrl?: string | null;
        fullName?: string | null;
        initials?: string | null;
      };
      username?: string | null;
    };
  }> | null;
};

export const validateCardMemberData = (memberData: MemberData) => {
  return (
    Array.isArray(memberData.edges) &&
    memberData.edges.every((edge) => {
      const node = edge?.node;
      if (node.objectId === undefined) {
        return false;
      }

      return (
        typeof node.objectId === 'string' &&
        (typeof node.avatarUrl === 'string' || node.avatarUrl === null) &&
        (typeof node.fullName === 'string' || node.fullName === null) &&
        (typeof node.initials === 'string' || node.initials === null) &&
        typeof node.username === 'string' &&
        ((!!node.nonPublicData &&
          (typeof node.nonPublicData.avatarUrl === 'string' ||
            node.nonPublicData.avatarUrl === null ||
            node.nonPublicData.avatarUrl === undefined) &&
          (typeof node.nonPublicData.fullName === 'string' ||
            node.nonPublicData.fullName === null ||
            node.nonPublicData.fullName === undefined) &&
          (typeof node.nonPublicData.initials === 'string' ||
            node.nonPublicData.initials === null ||
            node.nonPublicData.initials === undefined)) ||
          node.nonPublicData === null)
      );
    })
  );
};
