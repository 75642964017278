import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { TrelloList } from '../generated';

export const formatTrelloListData = (
  readField: ReadFieldFunction,
  trelloListRef: Readonly<TrelloList> | undefined,
  boardId: string,
) => {
  const listId = readField<string>('objectId', trelloListRef) || '';
  const name = readField<string>('name', trelloListRef);

  return {
    objectId: listId,
    name,
    board: {
      objectId: boardId,
    },
  };
};
