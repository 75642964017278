import { useCallback } from 'react';

import { useAssignPersonalProductivityCohortMutation } from './AssignPersonalProductivityCohortMutation.generated';

type AssignmentValue = 'ga' | 'new_user_holdout' | 'opted_in' | 'opted_out';

/**
 * Hook to assign the personal productivity cohort to the member
 * @returns An object with the following properties:
 * - assignPersonalProductivityBetaCohort: Function that assigns the cohort
 * to the member
 */
export const useAssignPersonalProductivityCohort = () => {
  const [assignPersonalProductivityCohort] =
    useAssignPersonalProductivityCohortMutation();

  /**
   * This hook will throw errors and should be handled by the caller
   */
  const assignPersonalProductivityBetaCohort = useCallback(
    async (assignmentValue: AssignmentValue) => {
      const { data, errors } = await assignPersonalProductivityCohort({
        variables: { assignmentValue },
      });

      if (errors) {
        throw errors;
      }

      const cohorts = data?.assignPersonalProductivityCohort?.cohorts;

      if (
        !cohorts?.find(
          (cohort) =>
            cohort?.name === 'userCohortPersonalProductivity' &&
            cohort?.value === assignmentValue,
        )
      ) {
        throw new Error(
          `expected cohort name and value missing, cohorts: ${JSON.stringify(cohorts)}`,
        );
      }
    },
    [assignPersonalProductivityCohort],
  );

  return {
    assignPersonalProductivityBetaCohort,
  };
};
