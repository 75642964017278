import emojiRegex from 'emoji-regex-xs';

import { dangerouslyGetFeatureGateSync } from '@trello/feature-gate-client';

export const CONFETTI_KEYWORDS = ['🎊', '🎉', '🎆', '🎇', '👖', '✨'];
// eslint-disable-next-line @trello/no-module-logic
const regex = emojiRegex();

export function shouldFireConfetti(name: string) {
  return dangerouslyGetFeatureGateSync('trello_emoji_confetti')
    ? Boolean(name.match(regex)?.length)
    : CONFETTI_KEYWORDS.some((keyword) => name.includes(keyword));
}
