// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jxci0vYzpSWqVc {
  position: relative;
  bottom: 32px;
  left: 32px;
}
@media only screen and (max-width: 600px) {
  .Jxci0vYzpSWqVc {
    bottom: 0;
    left: 0;
  }
}
.ccT966w3xDgoQc {
  position: absolute;
  bottom: 0;
  width: 400px;
  transition: transform 400ms ease-in-out;
}
@media (max-width: 560px) {
  .ccT966w3xDgoQc {
    width: 100vw;
  }
}
.ccT966w3xDgoQc:first-of-type {
  transform: translate(0, 0);
  transition: none;
}
.ccT966w3xDgoQc:nth-of-type(n + 2) {
  transform: translateX(0) translateY(100%) translateY(16px);
  animation-duration: 0ms;
}
.ccT966w3xDgoQc:nth-of-type(1) {
  z-index: 5;
}
.ccT966w3xDgoQc:nth-of-type(2) {
  z-index: 4;
}
.ccT966w3xDgoQc:nth-of-type(n + 4) {
  visibility: hidden;
}
.VJDDW7WK2qHwly:nth-of-type(n + 2) {
  transform: translate(0, 0);
  transition-duration: 200ms;
}
.SBxsLgkzwz5ylN {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  white-space: nowrap;
}
.a4ZvSL0pjeULBU {
  z-index: 600;
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  transition: background-color 200ms;
  border-radius: 3px;
  background-color: var(--ds-surface-overlay, #ffffff);
  box-shadow: var(--ds-shadow-overlay, 0px 8px 12px #091e4226, 0px 0px 1px #091e424f);
  color: var(--ds-text, #172b4d);
}
.a4ZvSL0pjeULBU:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--ds-border-focused, #388bff);
}
.YEctMXs9uZbttS {
  display: flex;
  align-items: center;
  min-height: 32px;
}
.QMKgZFIlTLiEJN {
  flex: 1;
  padding: 0 0 0 16px;
  color: var(--ds-text, #172b4d);
  font-weight: 600;
  word-break: break-word;
}
.LbO_k5JPG5miXd {
  flex: 0 0 auto;
  margin-left: 8px;
  padding: 0;
  border: none;
  border-radius: 3px;
  background: none;
  color: var(--ds-text, #172b4d);
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}
.LbO_k5JPG5miXd:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--ds-border-focused, #388bff);
}
.fC2l9XLJiWUIDR,
._R6kbzDFHRftNE {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  padding-left: 40px;
}
._R6kbzDFHRftNE {
  margin-top: 4px;
}
.SVN1W0FlnB0uEZ {
  overflow: auto;
  color: var(--ds-text, #172b4d);
  word-wrap: break-word;
}
.UILoLHChIm645X {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.MPBh3K1OnRKTTs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 8px;
  transform: translateX(-2px);
}
.aYwj06NoEI9xrS {
  color: var(--ds-link, #0c66e4);
  font-weight: 500;
  cursor: pointer;
}
.aYwj06NoEI9xrS:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .aYwj06NoEI9xrS:focus {
  outline-color: -webkit-focus-ring-color;
}
.aYwj06NoEI9xrS:focus .mac.firefox {
  outline-color: AccentColor;
}
.aYwj06NoEI9xrS:hover {
  color: var(--ds-link, #0c66e4);
  text-decoration: underline;
}
.WdHfFTCyWxdolL {
  display: inline-block;
  width: 16px;
  text-align: center;
}
.c8INiD2cEEMV1G {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  line-height: 1;
  vertical-align: bottom;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flagGroup": `Jxci0vYzpSWqVc`,
	"flagGroupChildren": `ccT966w3xDgoQc`,
	"dismissAllowed": `VJDDW7WK2qHwly`,
	"flagGroupLabel": `SBxsLgkzwz5ylN`,
	"flag": `a4ZvSL0pjeULBU`,
	"header": `YEctMXs9uZbttS`,
	"title": `QMKgZFIlTLiEJN`,
	"dismissButton": `LbO_k5JPG5miXd`,
	"expander": `fC2l9XLJiWUIDR`,
	"descriptionExpander": `_R6kbzDFHRftNE`,
	"description": `SVN1W0FlnB0uEZ`,
	"truncateDescription": `UILoLHChIm645X`,
	"actions": `MPBh3K1OnRKTTs`,
	"actionLink": `aYwj06NoEI9xrS`,
	"actionSeparator": `WdHfFTCyWxdolL`,
	"imageIcon": `c8INiD2cEEMV1G`
};
export default ___CSS_LOADER_EXPORT___;
