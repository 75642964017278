import type { FunctionComponent } from 'react';
import type { IntlShape } from 'react-intl';

import {
  GlobalFeedbackKey,
  PersonalProductivityEntryPointId,
  useFeedbackCollector,
} from '@trello/feedback-collector';

/**
 * Generates a FeedbackCollector and display callback preconfigured for
 * the Personal Productivity global feedback JSM endpoint.
 */
export const useGlobalFeedbackCollector = (
  intl: IntlShape,
): {
  FeedbackCollector: FunctionComponent;
  showFeedbackCollector: () => void;
} => {
  return useFeedbackCollector({
    entrypointId: PersonalProductivityEntryPointId,
    source: 'appHeader',
    attributes: { 'project-key': GlobalFeedbackKey },
    feedbackCollectorProps: {
      feedbackTitle: intl.formatMessage({
        id: 'templates.header_feedback.feedback-modal-title',
        defaultMessage:
          'Share your thoughts on the new Personal Productivity experience',
        description:
          'Invitation for the user to share their feedback about the new Personal Productivity features',
      }),
    },
  });
};
