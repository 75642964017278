// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IfckxJ5PbpJuxT {
  margin: 0 -12px;
}
.IfckxJ5PbpJuxT > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.gJDsPins_eYkBM {
  border: none;
  background-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 12px;
  transition: none;
  border-radius: 0;
  outline: 0;
  background: var(--ds-background-neutral-subtle, #00000000);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
  text-align: left;
  text-decoration: none;
}
.gJDsPins_eYkBM:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: var(--ds-background-neutral-subtle-hovered, #091e420f);
  color: var(--ds-text, #172b4d);
  text-decoration: none;
}
.gJDsPins_eYkBM:active {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: var(--ds-background-neutral-subtle-pressed, #091e4224);
  color: var(--ds-text, #172b4d);
}
.gJDsPins_eYkBM.hXWrVJZoYyCWaj:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .gJDsPins_eYkBM.hXWrVJZoYyCWaj:focus {
  outline-color: -webkit-focus-ring-color;
}
.gJDsPins_eYkBM.hXWrVJZoYyCWaj:focus .mac.firefox {
  outline-color: AccentColor;
}
.gJDsPins_eYkBM[disabled],
.gJDsPins_eYkBM[disabled]:active,
.gJDsPins_eYkBM[disabled]:focus,
.gJDsPins_eYkBM[disabled]:hover {
  background: transparent;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
  cursor: not-allowed;
}
.gJDsPins_eYkBM[disabled] .h9hY0e3uakbvPT svg,
.gJDsPins_eYkBM[disabled]:active .h9hY0e3uakbvPT svg,
.gJDsPins_eYkBM[disabled]:focus .h9hY0e3uakbvPT svg,
.gJDsPins_eYkBM[disabled]:hover .h9hY0e3uakbvPT svg {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
}
.gJDsPins_eYkBM[disabled] span[name='check'],
.gJDsPins_eYkBM[disabled]:active span[name='check'],
.gJDsPins_eYkBM[disabled]:focus span[name='check'],
.gJDsPins_eYkBM[disabled]:hover span[name='check'] {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-icon-subtle, #626f86) !important;
}
.gJDsPins_eYkBM[disabled] .kgXqyT2weJmrQm,
.gJDsPins_eYkBM[disabled]:active .kgXqyT2weJmrQm,
.gJDsPins_eYkBM[disabled]:focus .kgXqyT2weJmrQm,
.gJDsPins_eYkBM[disabled]:hover .kgXqyT2weJmrQm {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
}
.LCeoUSr_PkZrP2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.LCeoUSr_PkZrP2.DRX_7shCoCpAU5 {
  margin: 0;
}
.qsnqBHaMsbNgMF {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin: 0;
  padding: 6px 12px;
}
.qsnqBHaMsbNgMF:hover {
  background: var(--ds-background-neutral-subtle-hovered, #091e420f);
  color: var(--ds-text, #172b4d);
}
.qsnqBHaMsbNgMF.Hn8oXBIU7_B1Iz {
  background: transparent;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
  cursor: not-allowed;
}
.qsnqBHaMsbNgMF.Hn8oXBIU7_B1Iz .kgXqyT2weJmrQm {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
}
.qsnqBHaMsbNgMF .Y13jevGELVqVEh {
  display: none;
}
.qsnqBHaMsbNgMF .h9hY0e3uakbvPT {
  flex: 0 0 auto;
  opacity: 0;
}
.qsnqBHaMsbNgMF .Y13jevGELVqVEh:checked ~ .h9hY0e3uakbvPT {
  opacity: 1;
}
.qsnqBHaMsbNgMF .fup4oZ5HY3KdD8 {
  flex: 0 0 auto;
}
.qsnqBHaMsbNgMF .wfj__FaUUGQGeV {
  flex: 1 1 auto;
  max-width: fit-content;
}
.BmRHtH7FIX0jcL {
  display: flex;
  align-items: center;
}
.REGdK7LUxpdgvv,
.rnYaSURE60hdsz {
  flex-shrink: 0;
  max-height: 20px;
  padding: 0 4px;
}
.kgXqyT2weJmrQm {
  margin: 4px 0 0 0;
  padding: 0;
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.hDigGK0jR2_0pl {
  list-style: none;
}
.hDigGK0jR2_0pl::after {
  content: ' ';
  display: block;
  height: 1px;
  margin: 8px 10px;
  background-color: var(--ds-border, #091e4224);
}
.ZleRfV9IUYaZhl::after {
  margin-right: 0;
  margin-left: 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popoverMenu": `IfckxJ5PbpJuxT`,
	"link": `gJDsPins_eYkBM`,
	"linkFocusRing": `hXWrVJZoYyCWaj`,
	"icon": `h9hY0e3uakbvPT`,
	"description": `kgXqyT2weJmrQm`,
	"item": `LCeoUSr_PkZrP2`,
	"withoutMargins": `DRX_7shCoCpAU5`,
	"radio": `qsnqBHaMsbNgMF`,
	"disabled": `Hn8oXBIU7_B1Iz`,
	"input": `Y13jevGELVqVEh`,
	"checkmark": `fup4oZ5HY3KdD8`,
	"content": `wfj__FaUUGQGeV`,
	"title": `BmRHtH7FIX0jcL`,
	"iconBefore": `REGdK7LUxpdgvv`,
	"iconAfter": `rnYaSURE60hdsz`,
	"separator": `hDigGK0jR2_0pl`,
	"separator--fullWidth": `ZleRfV9IUYaZhl`
};
export default ___CSS_LOADER_EXPORT___;
