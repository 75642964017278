// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dcYK3PSJZLOm0q {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.PiRkVxU7_jShyt {
  margin-bottom: 6px;
  color: var(--ds-text-subtle, #44546f);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinnerContainer": `dcYK3PSJZLOm0q`,
	"spinnerMessage": `PiRkVxU7_jShyt`
};
export default ___CSS_LOADER_EXPORT___;
