import type { FetchResult, NextLink, Operation } from '@apollo/client/core';
import { ApolloLink, Observable } from '@apollo/client/core';
import { isSubscriptionOperation } from '@apollo/client/utilities';
import debounce from 'debounce';

import { Analytics } from '@trello/atlassian-analytics';
import { aggSchemaOverride, aggServiceOverride } from '@trello/config';

import { cache } from '../cache';
import { queryFromPojo } from '../syncDeltaToCache/queryFromPojo';
import type { JSONArray, JSONObject, JSONValue } from '../types';

// Queries and subscriptions currently return different types.
// However, we want to maintain one entry per model in the Apollo
// cache, regardless if the data came in via a query or subscription.
// This object maps from the subscription typename to the query typename
// to leverage Apollo's normalization on the `${__typename}:${id}` cache key.
// This is manually maintained for now, but we should explore a way to automate or
// handle more generically via the schema.
const SUBSCRIPTION_TYPENAME_MAPPING: { [key: string]: string } = {
  TrelloBoardUpdated: 'TrelloBoard',
  TrelloLabelConnectionUpdated: 'TrelloLabelConnection',
  TrelloLabelEdgeUpdated: 'TrelloLabelEdge',
  TrelloLabelUpdated: 'TrelloLabel',
  TrelloLabelDeleted: 'TrelloLabel',
  TrelloBoardWorkspaceUpdated: 'TrelloWorkspace',
  TrelloBoardViewerUpdated: 'TrelloBoardViewer',
};

const shouldMapSubscriptionTypename = (typename: string) => {
  return typename in SUBSCRIPTION_TYPENAME_MAPPING;
};

const getMappedSubscriptionTypename = (typename: string) => {
  return SUBSCRIPTION_TYPENAME_MAPPING[typename];
};

// NOTE: typeof [] === 'object'
const isObjectOrArray = (node: JSONValue): node is JSONArray | JSONObject =>
  typeof node === 'object';

const debouncedBroadcast = debounce(() => {
  // @ts-expect-error
  cache.broadcastWatches();
}, 100);

const getPathToNode = (parentKey: string, key: string): string => {
  const isRoot = parentKey === '';
  return isRoot ? key : `${parentKey}.${key}`;
};

// Deletions
const DELETION_TYPENAMES: string[] = [
  'TrelloLabelDeleted',
  'TrelloCustomFieldDeleted',
];

const isDeletionType = (typename: string) =>
  DELETION_TYPENAMES.includes(typename);

const deleteFromCache = (node: JSONObject) => {
  node.__typename = getMappedSubscriptionTypename(node.__typename as string);
  cache.evict({ id: cache.identify(node) });
};

const isDeletionArray = (array: JSONArray): boolean => {
  if (array.length > 0 && array[0]) {
    return isDeletionType(
      (array[0] as JSONObject).__typename?.toString() ?? '',
    );
  }
  return false;
};

// Return the subscriptionName, operationName, and ids to track
// the number of channels we're creating. Manually select these
// variables so we don't accidentally send UGC in analytics.
export const getSubscriptionAnalyticsAttributes = (
  subscriptionName: string,
  mutableOperation: {
    operationName?: string;
    variables?: {
      nodeId?: string;
      nodeIds?: string[];
      memberId?: string;
      boardId?: string;
    };
  },
) => {
  const {
    operationName,
    variables: { nodeId, nodeIds = [], memberId, boardId } = {},
  } = mutableOperation;

  return {
    subscriptionName,
    ...(operationName && { operationName }),
    ...(nodeId && { nodeId }),
    ...(nodeIds.length > 0 && { nodeIds }),
    ...(memberId && { memberId }),
    ...(boardId && { boardId }),
  };
};

/**
 * Return a new object with valid values from the subscription response. Recurse through
 * the original response and use deltas to validate null values. Also, strip 'Updated'
 * from typenames to update normalized entities in the cache.
 *
 * See: https://hello.atlassian.net/wiki/spaces/TRFC/pages/4020701349
 *
 * @param {JSONObject | JSONArray} currentNode The current node to traverse in the subscription response
 * @param {string} parentKey The flattened path to the node in dot notation
 * @param {string[]} deltas The list of valid values in the array
 * @returns {JSONObject | JSONArray} A new object with cleaned subscription response
 */
export function cleanSubscriptionResponse(
  currentNode: JSONArray | JSONObject,
  parentKey: string,
  deltas: string[],
): JSONArray | JSONObject {
  // Handle first function call if non-object is passed in
  // Otherwise, we only recurse through objects and arrays
  if (currentNode === null || !isObjectOrArray(currentNode)) {
    return currentNode;
  }

  // If array, iterate over elements
  if (Array.isArray(currentNode)) {
    const newArrayNode: JSONArray = [];
    currentNode.forEach((item, index) => {
      const newKey = getPathToNode(parentKey, String(index));
      if (item !== null && isObjectOrArray(item)) {
        // If item is object or array, recurse
        newArrayNode[index] = cleanSubscriptionResponse(item, newKey, deltas);
      } else if (item === null && !deltas.includes(newKey)) {
        // Skip null values not in deltas
      } else {
        // Copy value to new node
        newArrayNode[index] = item;
      }
    });

    return newArrayNode;
  }

  // If object, iterate through keys
  const newObjectNode: JSONObject = {};
  Object.keys(currentNode).forEach((key) => {
    // Skip _deltas so it doesn't get cached
    if (key === '_deltas') {
      return;
    }

    const newKey = getPathToNode(parentKey, key);
    const item = currentNode[key];

    if (item !== null && isObjectOrArray(item)) {
      // If item is array, check if it's an array of "delete events"
      if (Array.isArray(item) && isDeletionArray(item)) {
        item.forEach((deletionNode) =>
          deleteFromCache(deletionNode as JSONObject),
        );
      } else {
        // Otherwise, recurse
        newObjectNode[key] = cleanSubscriptionResponse(item, newKey, deltas);
      }
    } else if (item === null && !deltas.includes(newKey)) {
      // Skip null values not in deltas
    } else if (
      key === '__typename' &&
      typeof item === 'string' &&
      shouldMapSubscriptionTypename(item)
    ) {
      newObjectNode[key] = getMappedSubscriptionTypename(item);
    } else {
      // Copy value to new node
      newObjectNode[key] = item;
    }
  });

  return newObjectNode;
}
/**
 * Temporary solution to process subscription responses coming through native GraphQL,
 * while they have separate type and _deltas property on them.
 *
 * This is an Apollo link for ws messages the inspects the data and validates
 * null values based on their existence in _deltas. This handles server's
 * partial updates and writes them to the cache.
 * @example
 * const client = new ApolloClient({
 *   cache: new InMemoryCache(),
 *   link: ApolloLink.from([cacheSubscriptionResponseLink, httpLink]),
 * });
 *
 * @param {Operation} operation The GraphQL operation being executed.
 * @param {NextLink} forward A function that forwards the operation to the next link in the chain.
 * @returns {Observable<FetchResult>} An observable that emits the result of the operation.
 */
// eslint-disable-next-line @trello/no-module-logic
export const cacheSubscriptionResponseLink = new ApolloLink(
  (operation: Operation, forward: NextLink) => {
    // Check if the operation contains a subscription
    if (!isSubscriptionOperation(operation.query)) {
      return forward(operation);
    }

    // Mutating args is side-effecty and bad IMO, so we'll make a deep copy
    const mutableOperation = JSON.parse(JSON.stringify(operation));
    if (process.env.NODE_ENV === 'development') {
      mutableOperation.extensions = {
        ...(mutableOperation.extensions ?? {}),
        ...(aggServiceOverride ? { serviceOverride: aggServiceOverride } : {}),
        ...(aggSchemaOverride ? { schema: aggSchemaOverride } : {}),
      };
    }

    // Process the operation and its result here
    return new Observable((observer) => {
      let traceId: string | null = Analytics.startTask({
        taskName: 'create-subscription/socket/graphql',
        source: '@trello/graphql',
      });

      const subscription = forward(mutableOperation).subscribe({
        next: (result: FetchResult) => {
          if (result.data) {
            // There's only one root subscription at a time. Get the
            // subscriptionName, and then get its data.
            const subscriptionName = Object.keys(result.data.trello)[0];

            if (traceId) {
              Analytics.taskSucceeded({
                taskName: 'create-subscription/socket/graphql',
                source: '@trello/graphql',
                traceId,
                attributes: getSubscriptionAnalyticsAttributes(
                  subscriptionName,
                  mutableOperation,
                ),
              });
              traceId = null;
            }

            const data = result.data.trello[subscriptionName];

            // _deltas is null on the initial subscription response
            const deltas = data._deltas === null ? [] : data._deltas;
            const id = data.id;

            // The subscription response can't be trusted because it may have null fields that were
            // not included in server's partial update. Delete all null fields unless they were
            // explicitly included in _deltas
            const cleanedData = {
              trello: {
                [subscriptionName]: cleanSubscriptionResponse(data, '', deltas),
              },
            };

            cache.writeQuery({
              query: queryFromPojo(cleanedData, { id }),
              data: cleanedData,
              variables: {
                id,
              },
              broadcast: false, // don't broadcast every single update
            });

            // Garbage collect after the cache is updated to remove any unreachable refs
            cache.gc();

            if (
              result.extensions?.trello.traceId &&
              result.extensions.trello.spanId
            ) {
              Analytics.taskSucceeded({
                taskName: 'send-message',
                source: '@trello/graphql',
                traceId: result.extensions.trello.traceId,
                spanId: result.extensions.trello.spanId,
                attributes: {
                  id,
                  subscriptionName,
                },
              });
            }

            // broadcast every 100ms
            debouncedBroadcast();
          }

          observer.next(result);
        },
        error: (error) => {
          if (traceId) {
            Analytics.taskFailed({
              taskName: 'create-subscription/socket/graphql',
              source: '@trello/graphql',
              traceId,
              error,
            });
            traceId = null;
          }
          observer.error(error);
        },
        complete: () => {
          if (traceId) {
            Analytics.taskAborted({
              taskName: 'create-subscription/socket/graphql',
              source: '@trello/graphql',
              traceId,
            });
            traceId = null;
          }
          observer.complete();
        },
      });
      // Cleanup
      return () => {
        if (subscription) subscription.unsubscribe();
        if (traceId) {
          Analytics.taskAborted({
            taskName: 'create-subscription/socket/graphql',
            source: '@trello/graphql',
            traceId,
          });
        }
      };
    });
  },
);
