import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { type TrelloCard } from '../generated';

export const formatTrelloAttachmentsData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const attachments = readField<TrelloCard['attachments']>(
    'attachments',
    trelloCardRef,
  );
  const attachmentEdges = attachments?.edges?.map((edge) => {
    const nodeRef = edge.node;
    const objectId = readField<string>('objectId', nodeRef);
    const isMalicious = readField<boolean>('isMalicious', nodeRef);
    return { node: { isMalicious, objectId } };
  });

  return { edges: attachmentEdges ?? [] };
};
