import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type CardFrontBoardFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id' | 'idEnterprise' | 'idOrganization' | 'powerUps'>
  & { boardPlugins: Array<(
    { __typename: 'BoardPlugin' }
    & Pick<Types.BoardPlugin, 'id' | 'idPlugin'>
  )> }
);

export const CardFrontBoardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardFrontBoard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardPlugins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idPlugin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'idEnterprise' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idOrganization' } },
          { kind: 'Field', name: { kind: 'Name', value: 'powerUps' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseCardFrontBoardFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      CardFrontBoardFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseCardFrontBoardFragmentResult
  extends Omit<Apollo.UseFragmentResult<CardFrontBoardFragment>, 'data'> {
  data?: CardFrontBoardFragment;
}

export const useCardFrontBoardFragment = ({
  from,
  returnPartialData,
  ...options
}: UseCardFrontBoardFragmentOptions): UseCardFrontBoardFragmentResult => {
  const result = Apollo.useFragment<CardFrontBoardFragment>({
    ...options,
    fragment: CardFrontBoardFragmentDoc,
    fragmentName: 'CardFrontBoard',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as CardFrontBoardFragment };
};
