// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fHf7AF1CnxctHD {
  box-sizing: border-box;
  border: none;
  outline: none;
  color: var(--ds-text, #172b4d);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-display: swap;
  transition-property: background-color, border-color, box-shadow;
  transition-duration: 85ms;
  transition-timing-function: ease;
  padding: 8px 12px;
  border-radius: 3px;
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 1px var(--ds-border-input, #091e4224);
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  padding: 12px;
}
.Z4jXXHjcMEV0u2 {
  background-color: var(--ds-background-input, #ffffff);
  box-shadow: inset 0 0 0 2px var(--ds-border-focused, #388bff);
}
.Egc9nRS1wSw1bp,
.Egc9nRS1wSw1bp:hover {
  box-shadow: inset 0 0 0 1px var(--ds-border-danger, #e34935);
}
.UHMPdzCGEL3k6n {
  background-color: var(--ds-background-disabled, #091e4208);
  box-shadow: none;
  color: var(--ds-text-disabled, #091e424f);
  cursor: not-allowed;
  -webkit-user-select: none;
          user-select: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `fHf7AF1CnxctHD`,
	"input_focus": `Z4jXXHjcMEV0u2`,
	"input_invalid": `Egc9nRS1wSw1bp`,
	"input_disabled": `UHMPdzCGEL3k6n`
};
export default ___CSS_LOADER_EXPORT___;
