import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloCustomFieldItemConnection,
  TrelloCustomFieldOptionValue,
} from '../generated';
import {
  type TrelloCard,
  type TrelloCustomField,
  type TrelloCustomFieldDisplay,
  type TrelloCustomFieldItemValueInfo,
  type TrelloCustomFieldOption,
} from '../generated';

export const formatTrelloCardCustomFieldItemsData = (
  readField: ReadFieldFunction,
  customFieldItems?: Readonly<TrelloCustomFieldItemConnection> | null,
) => {
  if (
    customFieldItems?.edges === undefined ||
    customFieldItems.edges === null
  ) {
    return { edges: [] };
  }

  const formattedEdges = customFieldItems.edges
    .map((edge) => {
      const customFieldItemRef = edge?.node;
      if (customFieldItemRef === null || customFieldItemRef === undefined) {
        return null;
      }

      const objectId = readField<string>('objectId', customFieldItemRef);
      if (objectId === undefined) {
        return null;
      }

      const customFieldRef = readField<TrelloCustomField>(
        'customField',
        customFieldItemRef,
      );

      const displayRef = readField<TrelloCustomFieldDisplay>(
        'display',
        customFieldRef,
      );

      const display = {
        cardFront: readField<boolean>('cardFront', displayRef),
      };

      const optionsRef = readField<TrelloCustomFieldOption[]>(
        'options',
        customFieldRef,
      );

      const options = (optionsRef || []).map((optionRef) => {
        const valueRef = readField<TrelloCustomFieldOptionValue>(
          'value',
          optionRef,
        );
        const value = {
          text: readField<string>('text', valueRef),
        };
        return {
          color: readField<string>('color', optionRef),
          objectId: readField<string>('objectId', optionRef),
          position: readField<number>('position', optionRef),
          value,
        };
      });

      const customField = {
        display,
        name: readField<string>('name', customFieldRef),
        objectId: readField<string>('objectId', customFieldRef),
        options,
        position: readField<number>('position', customFieldRef),
        type: readField<string>('type', customFieldRef),
      };

      const modelRef = readField<TrelloCard>('model', customFieldItemRef);

      const model = {
        objectId: readField<string>('objectId', modelRef),
      };

      const valueRef = readField<TrelloCustomFieldItemValueInfo>(
        'value',
        customFieldItemRef,
      );

      const value = {
        checked: readField<boolean>('checked', valueRef),
        date: readField<string>('date', valueRef),
        id: readField<string>('id', valueRef),
        number: readField<number>('number', valueRef),
        text: readField<string>('text', valueRef),
      };

      return {
        node: {
          customField,
          model,
          objectId,
          value,
        },
      };
    })
    .filter((edge) => edge !== null);

  return { edges: formattedEdges };
};
