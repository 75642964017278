import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const AssignPersonalProductivityCohortDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AssignPersonalProductivityCohort"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"assignmentValue"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"PersonalProductivityAssignmentValue"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"assignPersonalProductivityCohort"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"assignmentValue"},"value":{"kind":"Variable","name":{"kind":"Name","value":"assignmentValue"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cohorts"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"AssignPersonalProductivityCohort","document":AssignPersonalProductivityCohortDocument}} as const;
export type AssignPersonalProductivityCohortMutationVariables = Types.Exact<{
  assignmentValue: Types.PersonalProductivityAssignmentValue;
}>;


export type AssignPersonalProductivityCohortMutation = (
  { __typename: 'Mutation' }
  & { assignPersonalProductivityCohort?: Types.Maybe<(
    { __typename: 'PersonalProductivityAssignmentOutput' }
    & { cohorts?: Types.Maybe<Array<Types.Maybe<(
      { __typename: 'PersonalProductivityCohort' }
      & Pick<Types.PersonalProductivityCohort, 'name' | 'value'>
    )>>> }
  )> }
);

export type AssignPersonalProductivityCohortMutationFn =
  Apollo.MutationFunction<
    AssignPersonalProductivityCohortMutation,
    AssignPersonalProductivityCohortMutationVariables
  >;

/**
 * __useAssignPersonalProductivityCohortMutation__
 *
 * To run a mutation, you first call `useAssignPersonalProductivityCohortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignPersonalProductivityCohortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignPersonalProductivityCohortMutation, { data, loading, error }] = useAssignPersonalProductivityCohortMutation({
 *   variables: {
 *      assignmentValue: // value for 'assignmentValue'
 *   },
 * });
 */
export function useAssignPersonalProductivityCohortMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignPersonalProductivityCohortMutation,
    AssignPersonalProductivityCohortMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignPersonalProductivityCohortMutation,
    AssignPersonalProductivityCohortMutationVariables
  >(AssignPersonalProductivityCohortDocument, options);
}
export type AssignPersonalProductivityCohortMutationHookResult = ReturnType<
  typeof useAssignPersonalProductivityCohortMutation
>;
export type AssignPersonalProductivityCohortMutationResult =
  Apollo.MutationResult<AssignPersonalProductivityCohortMutation>;
export type AssignPersonalProductivityCohortMutationOptions =
  Apollo.BaseMutationOptions<
    AssignPersonalProductivityCohortMutation,
    AssignPersonalProductivityCohortMutationVariables
  >;
