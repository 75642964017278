// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.s_r1D9OhMvhi3F {
  margin-left: 8px;
}
.QEoKH2sHHAt2Z0 {
  margin: 1px;
  list-style: none;
}
.EZj9VI4TUpMILw {
  margin-left: 8px;
}
.FkrYmxx7RrIZxt {
  border: none;
  background-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 6px 12px;
  transition: none;
  border-radius: 0;
  outline: 0;
  background: var(--ds-background-neutral-subtle, #00000000);
  box-shadow: none;
  color: var(--ds-text, #172b4d);
  text-align: left;
  text-decoration: none;
}
.FkrYmxx7RrIZxt:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: var(--ds-background-neutral-subtle-hovered, #091e420f);
  color: var(--ds-text, #172b4d);
  text-decoration: none;
}
.FkrYmxx7RrIZxt:active {
  border: none;
  background-color: transparent;
  box-shadow: none;
  background: var(--ds-background-neutral-subtle-pressed, #091e4224);
  color: var(--ds-text, #172b4d);
}
.FkrYmxx7RrIZxt.hfasDCkVMeACDv:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .FkrYmxx7RrIZxt.hfasDCkVMeACDv:focus {
  outline-color: -webkit-focus-ring-color;
}
.FkrYmxx7RrIZxt.hfasDCkVMeACDv:focus .mac.firefox {
  outline-color: AccentColor;
}
.FkrYmxx7RrIZxt[disabled],
.FkrYmxx7RrIZxt[disabled]:active,
.FkrYmxx7RrIZxt[disabled]:focus,
.FkrYmxx7RrIZxt[disabled]:hover {
  background: transparent;
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
  cursor: not-allowed;
}
.FkrYmxx7RrIZxt[disabled] .YvORzsyyNbe3B9 svg,
.FkrYmxx7RrIZxt[disabled]:active .YvORzsyyNbe3B9 svg,
.FkrYmxx7RrIZxt[disabled]:focus .YvORzsyyNbe3B9 svg,
.FkrYmxx7RrIZxt[disabled]:hover .YvORzsyyNbe3B9 svg {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
}
.FkrYmxx7RrIZxt[disabled] span[name='check'],
.FkrYmxx7RrIZxt[disabled]:active span[name='check'],
.FkrYmxx7RrIZxt[disabled]:focus span[name='check'],
.FkrYmxx7RrIZxt[disabled]:hover span[name='check'] {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-icon-subtle, #626f86) !important;
}
.FkrYmxx7RrIZxt[disabled] .jRSpDmdq_5WrT0,
.FkrYmxx7RrIZxt[disabled]:active .jRSpDmdq_5WrT0,
.FkrYmxx7RrIZxt[disabled]:focus .jRSpDmdq_5WrT0,
.FkrYmxx7RrIZxt[disabled]:hover .jRSpDmdq_5WrT0 {
  /* stylelint-disable-next-line declaration-no-important */
  color: var(--ds-text-disabled, #091e424f) !important;
}
.FkrYmxx7RrIZxt:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .FkrYmxx7RrIZxt:focus {
  outline-color: -webkit-focus-ring-color;
}
.FkrYmxx7RrIZxt:focus .mac.firefox {
  outline-color: AccentColor;
}
.rN0ca7nc9uVErQ {
  flex-shrink: 0;
  max-height: 20px;
  padding: 0 4px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chevron": `s_r1D9OhMvhi3F`,
	"menu": `QEoKH2sHHAt2Z0`,
	"title": `EZj9VI4TUpMILw`,
	"link": `FkrYmxx7RrIZxt`,
	"linkFocusRing": `hfasDCkVMeACDv`,
	"icon": `YvORzsyyNbe3B9`,
	"description": `jRSpDmdq_5WrT0`,
	"iconBefore": `rN0ca7nc9uVErQ`
};
export default ___CSS_LOADER_EXPORT___;
