class CacheSyncingError extends Error {
  readonly typeName: string;

  constructor(typeName: string, message: string, fieldName?: string) {
    super(
      `Could not sync ${typeName}${fieldName ? `.${fieldName}` : ''} to cache: ${message}`,
    );
    this.typeName = typeName;
  }
}

export class InvalidValueError extends CacheSyncingError {
  value: unknown;

  constructor(typeName: string, fieldName: string, value: unknown) {
    super(typeName, 'Invalid value', fieldName);
    this.value = value;
  }
}

export class MissingIdError extends CacheSyncingError {
  constructor(typeName: string, fieldName?: string) {
    super(typeName, `Missing id and objectId fields`, fieldName);
  }
}

export class InvalidIdError extends CacheSyncingError {
  id: unknown;

  constructor(typeName: string, id: unknown, fieldName?: string) {
    super(typeName, `Invalid ARI`, fieldName);
    this.id = id;
  }
}

export class MissingEdgesError extends CacheSyncingError {
  constructor(typeName: string, fieldName: string) {
    super(typeName, `Missing edges field on connection`, fieldName);
  }
}

export const wrapIdErrorInParent = (
  error: InvalidIdError | MissingIdError,
  parentType: string,
  fieldName?: string,
) => {
  if (error instanceof InvalidIdError) {
    return new InvalidIdError(parentType, error.id, fieldName);
  } else if (error instanceof MissingIdError) {
    return new MissingIdError(parentType, fieldName);
  }
};
