import type { InMemoryCache, Reference } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { sendErrorEvent } from '@trello/error-reporting';

import type { TrelloBoard, TrelloList } from '../generated';
import { type TrelloCard } from '../generated';
import {
  isBool,
  isEnumString,
  isObjectId,
  isString,
} from '../plannerCardDataMapping/validateHelpers';
import type { RecursivePartial, TargetModel } from './cacheModelTypes';
import {
  InvalidIdError,
  MissingIdError,
  wrapIdErrorInParent,
} from './cacheSyncingErrors';
import { getObjectIdFromCacheObject } from './getObjectIdFromCacheObject';
import { syncCardCover } from './syncCardCover';
import { syncCardDueInfo } from './syncCardDueInfo';
import { syncCardLimits } from './syncCardLimits';
import { syncListsOfModelRefs } from './syncListsOfModelRefs';
import { syncNativeToRestScalars } from './syncNativeToRestScalars';
import { syncNestedModelReference } from './syncNestedModelReference';
import { syncTrelloCardBadgesToCardBadges } from './syncTrelloCardBadgesToCardBadges';

/** Exported for testing purposes only */
export const scalarFieldMappings = {
  complete: { validate: isBool, key: 'dueComplete' },
  closed: { validate: isBool },
  isTemplate: { validate: isBool },
  lastActivityAt: { validate: isString, key: 'dateLastActivity' },
  mirrorSourceId: {
    validate: (value: unknown) => value === null || isObjectId(value),
  },
  name: { validate: isString },
  pinned: { validate: isBool },
  role: {
    validate: (value: unknown) =>
      value === null ||
      isEnumString(value, ['BOARD', 'LINK', 'MIRROR', 'SEPARATOR']),
    key: 'cardRole',
    transform: (value: string | null) => value?.toLowerCase() ?? null,
  },
  shortLink: { validate: isString },
  url: { validate: isString },
};

const nestedListMappings = {
  checklists: { idFieldName: 'idChecklists', type: 'Checklist' as const },
  customFieldItems: { type: 'CustomFieldItem' as const },
  labels: { idFieldName: 'idLabels', type: 'Label' as const },
  members: { idFieldName: 'idMembers', type: 'Member' as const },
  powerUpData: { type: 'PluginData' as const, key: 'pluginData' },
  stickers: { type: 'Sticker' as const },
};

/**
 * Given native TrelloCard data, writes all card data to the Card model
 * in the Apollo Cache
 * @param incoming A partial TrelloCard model
 * @param cache The cache to write to
 */
export const syncTrelloCardToCard = (
  incoming: RecursivePartial<TrelloCard> | Reference,
  cache: InMemoryCache,
  readField: ReadFieldFunction,
) => {
  try {
    const cardId = getObjectIdFromCacheObject(incoming, readField);
    const card: TargetModel = { id: cardId, type: 'Card' };

    syncNativeToRestScalars(
      card,
      scalarFieldMappings,
      incoming,
      cache,
      readField,
    );
    syncTrelloCardBadgesToCardBadges(card, incoming, cache, readField);

    // TODO: still need to sync label.idBoard
    syncListsOfModelRefs(card, nestedListMappings, incoming, cache, readField);

    syncCardDueInfo(card, incoming, cache, readField);
    syncCardLimits(card, incoming, cache, readField);
    syncCardCover(card, incoming, cache, readField);

    const list = readField<TrelloList>('list', incoming);
    if (list) {
      const listId = getObjectIdFromCacheObject(list, readField);
      syncNestedModelReference(
        card,
        {
          model: { id: listId, type: 'List' },
          fieldName: 'list',
          idFieldName: 'idList',
        },
        cache,
      );

      const board = readField<TrelloBoard>('board', list);
      if (board) {
        const boardId = getObjectIdFromCacheObject(board, readField);
        syncNestedModelReference(
          card,
          {
            model: { id: boardId, type: 'Board' },
            fieldName: 'board',
            idFieldName: 'idBoard',
          },
          cache,
        );
      }
    }
  } catch (err) {
    let error = err;
    if (err instanceof InvalidIdError || err instanceof MissingIdError) {
      if (err.typeName === 'TrelloList') {
        error = wrapIdErrorInParent(err, 'Card', 'list');
      }
      if (err.typeName === 'TrelloBoard') {
        error = wrapIdErrorInParent(err, 'Card', 'board');
      }
    }
    sendErrorEvent(error);
  }
};
