import type { CustomAttributes } from '@atlaskit/feature-gate-js-client';
import {
  FeatureGateEnvironment,
  PerimeterType,
} from '@atlaskit/feature-gate-js-client';
import type { FetcherOptions } from '@atlaskit/feature-gate-js-client/dist/types/client/fetcher';
import { Analytics } from '@trello/atlassian-analytics';
import { getAaId, getMemberId } from '@trello/authentication';
import { atlassianFeatureFlagClientKey, environment } from '@trello/config';
import { getScreenFromUrl } from '@trello/marketing-screens';

import { updateAllSharedStateFeatureGatesAndExperiments } from './state/updateAllSharedStateFeatureGatesAndExperiments';

export type FeatureGatesClientWrapperParams = {
  customAttributes: CustomAttributes;
  workspaceId: string | null;
};

export type InitParams = {
  fetchOptions: ReturnType<typeof getFetchOptions>;
  identifiers: ReturnType<typeof getIdentifiers>;
  customAttributes: CustomAttributes;
};

const getFeatureGateEnvironment = () => {
  let clientEnv = FeatureGateEnvironment.Development;
  if (environment === 'staging') {
    clientEnv = FeatureGateEnvironment.Staging;
  } else if (environment === 'prod') {
    clientEnv = FeatureGateEnvironment.Production;
  }
  return clientEnv;
};

const getFetchOptions: () => FetcherOptions = () => ({
  apiKey: atlassianFeatureFlagClientKey,
  environment: getFeatureGateEnvironment(),
  targetApp: 'trello_web',
  useGatewayURL: false,
  perimeter: PerimeterType.COMMERCIAL, // default to commercial until we need fedramp
});

const getIdentifiers = ({ workspaceId }: { workspaceId: string | null }) => ({
  trelloUserId: getMemberId() || undefined,
  trelloWorkspaceId: workspaceId || undefined,
  atlassianAccountId: getAaId() || undefined,
  analyticsAnonymousId:
    Analytics?.analytics?.getAnonymousId() ?? 'unidentified',
});

export const featureGatesClientWrapper =
  ({
    workspaceId,
    customAttributes,
    step,
  }: FeatureGatesClientWrapperParams & { step: 'init' | 'refresh' }) =>
  async (
    next: ({
      fetchOptions,
      identifiers,
    }: InitParams) => Promise<void> | undefined,
  ): Promise<void> => {
    const identifiers = getIdentifiers({ workspaceId });
    try {
      const fetchOptions = getFetchOptions();
      await next({
        fetchOptions,
        identifiers,
        customAttributes,
      });
    } catch (error) {
      if (error instanceof Error) {
        // Ignore set of request abort errors
        if (
          !/The user aborted a request\.|Fetch is aborted|The operation was aborted\./.test(
            error.message,
          )
        ) {
          const source = getScreenFromUrl();
          Analytics.sendOperationalEvent({
            action: 'errored',
            actionSubject: 'featureGateClient',
            actionSubjectId:
              step === 'init'
                ? 'initFeatureGateClient'
                : 'refreshFeatureGatesClient',
            source,
            attributes: {
              identifiers,
              customAttributes,
              error: error.message,
            },
          });
        }
      }
    } finally {
      updateAllSharedStateFeatureGatesAndExperiments();
    }
  };
