import {
  useMemo,
  type ForwardRefExoticComponent,
  type PropsWithChildren,
  type RefAttributes,
} from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { Spinner } from '@trello/nachos/spinner';

interface InfiniteListProps<TElement> {
  loadMore: () => void;
  hasMore: boolean;
  isLoading?: boolean;
  spinnerClassName?: string;
  ListContainer?: ForwardRefExoticComponent<
    PropsWithChildren & RefAttributes<TElement>
  >;
}

export const InfiniteList = <TElement extends HTMLElement>({
  loadMore,
  hasMore,
  isLoading = false,
  spinnerClassName,
  ListContainer,
  children,
}: PropsWithChildren<InfiniteListProps<TElement>>) => {
  const infiniteScrollProps = useMemo(
    () => ({
      loading: isLoading,
      hasNextPage: hasMore,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 300px 0px',
    }),
    [isLoading, hasMore, loadMore],
  );
  const [sentryRef, { rootRef }] = useInfiniteScroll(infiniteScrollProps);

  if (ListContainer) {
    return (
      <ListContainer ref={rootRef}>
        {children}
        {isLoading && <Spinner centered wrapperClassName={spinnerClassName} />}
        {hasMore && <div ref={sentryRef} />}
      </ListContainer>
    );
  }

  return (
    <>
      {children}
      {isLoading && <Spinner centered wrapperClassName={spinnerClassName} />}
      {hasMore && <div ref={sentryRef} />}
    </>
  );
};
