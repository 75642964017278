import type { Resolvers } from '@apollo/client';

import {
  announcementsResolver,
  dismissAnnouncement,
} from './resolvers/announcements';
import {
  createPluginApplication,
  getPluginApplicationResolver,
  updateApplication,
} from './resolvers/application';
import {
  addBoard,
  addMemberToBoard,
  boardActionsResolver,
  boardDashboardViewTileResolver,
  boardExportResolver,
  boardInviteSecretRequestResolver,
  boardListsResolver,
  boardsSearchResolver,
  createBoardInviteSecret,
  deleteBoard,
  deleteTag,
  disableBoardInviteSecret,
  editTag,
  enablePlugin,
  messageEmailKey,
  removeMemberFromBoard,
  startBoardExport,
  statsResolver,
  toggleTag,
  updateBackgroundPref,
  updateBoard,
  updateBoardBackgroundImage,
  updateBoardCardCoversPref,
  updateBoardCommentsPref,
  updateBoardHideVotesPref,
  updateBoardInvitationsPref,
  updateBoardMemberPermission,
  updateBoardMyPrefs,
  updateBoardOrg,
  updateBoardSelfJoinPref,
  updateBoardShowCompleteStatusPref,
  updateBoardSwitcherViewsPref,
  updateBoardTemplatePref,
  updateBoardVisibility,
  updateBoardVotingPref,
  updateCalendarFeedEnabledPref,
  updateCalendarKey,
  updateEmailKey,
  updateEmailPosition,
  updateHiddenPluginBoardButtonShortcuts,
} from './resolvers/board';
import {
  createDashboardViewTile,
  deleteDashboardViewTile,
  updateDashboardViewTile,
} from './resolvers/boardDashboard';
import {
  boardHistoryResolver,
  getCardsPerDueDateStatus,
  getCardsPerLabel,
  getCardsPerList,
  getCardsPerMember,
} from './resolvers/boardHistory';
import { addButlerButton, deleteButlerButton } from './resolvers/butlerButton';
import {
  addAttachmentToCard,
  addCardComment,
  addMemberToCard,
  addMemberVoted,
  addStickerToCard,
  archiveCard,
  archivedCardsForBoardResolver,
  cardActionsResolver,
  changeCardDueDate,
  copyCard,
  createCard,
  createCardFromFile,
  createCardTemplate,
  deleteCard,
  deleteCardComment,
  deleteMemberVoted,
  markAssociatedNotificationsRead,
  markCardAsViewed,
  possibleCardRoleResolver,
  removeAttachmentFromCard,
  removeMemberFromCard,
  removeStickerFromCard,
  setPinCard,
  unarchiveCard,
  updateCard,
  updateCardAttachment,
  updateCardComment,
  updateCardCover,
  updateCardDates,
  updateCardDueComplete,
  updateCardLabels,
  updateCardList,
  updateCardName,
  updateCardPos,
  updateCardRole,
  updateCardSubscription,
  uploadCardCover,
} from './resolvers/card';
import {
  addCheckItem,
  convertCheckItemToCard,
  deleteCheckItem,
  updateCheckItem,
} from './resolvers/checkItem';
import {
  addChecklist,
  deleteChecklist,
  updateChecklistName,
  updateChecklistPos,
} from './resolvers/checklist';
import {
  createOrder,
  createTransactionAccount,
  getOrderResolver,
} from './resolvers/cloudCommercePlatform';
import {
  addCustomFieldOption,
  createCustomField,
  deleteCustomField,
  deleteCustomFieldOption,
  updateCustomField,
  updateCustomFieldItem,
  updateCustomFieldOption,
} from './resolvers/customFields';
import { emailProviderResolver } from './resolvers/emailProvider';
import {
  assignMemberEnterpriseAdmin,
  auditlogResolver,
  claimOrganization,
  createSeatAutomationExport,
  createSelfServeExpansion,
  deactivateEnterpriseMember,
  declineOrganizations,
  defaultOrganizationResolver,
  deleteAllManagedMemberTokens,
  deleteManagedMemberTokens,
  dismissCompletedWorkspaceBatches,
  enterpriseClaimableOrganizationsResolver,
  enterpriseOrganizationsResolver,
  enterprisePendingOrganizationsResolver,
  fetchSeatAutomationExportResolver,
  getAssociatedWorkspacesForMemberResolver,
  getEnterpriseExportResolver,
  grantEnterpriseLicense,
  linkEnterpriseWithAtlassianOrganization,
  managedMembersWithTokensResolver,
  membershipsResolver,
  reactivateMember,
  removeEnterpriseMember,
  runSeatAutomation,
  seatAutomationBlocklistMembersResolver,
  seatAutomationHistoryResolver,
  seatAutomationNextRunDateResolver,
  seatAutomationPreviewResolver,
  selfServeExpansionEstimateResolver,
  startEnterpriseExport,
  transferrableDataForOrganizationResolver,
  updateDefaultWorkspace,
  updateEnterpriseApiTokenCreationPermission,
  updateEnterprisePrefsSeatAutomation,
  updateEnterprisePrefsSeatAutomationBlockedMembers,
} from './resolvers/enterprise';
import { createLabel, deleteLabel, updateLabel } from './resolvers/label';
import {
  archiveAllCards,
  archiveListMutation,
  copyList,
  createList,
  deleteList,
  moveAllCards,
  sortList,
  unarchiveListMutation,
  updateListColor,
  updateListDatasourceFilter,
  updateListDatasourceLink,
  updateListLimit,
  updateListName,
  updateListPosition,
  updateListSubscription,
} from './resolvers/list';
import {
  acceptDeveloperTerms,
  addBoardStar,
  addCampaign,
  addMessageDismissed,
  addOneTimeMessagesDismissed,
  addSavedSearch,
  deleteCustomBackground,
  deleteCustomSticker,
  deleteOneTimeMessagesDismissed,
  deleteSavedSearch,
  enableMemberProfileSync,
  inboxAssistantClassify,
  inboxAssistantExecute,
  memberActionsResolver,
  memberAgreementsResolver,
  memberAtlassianOrganizationsResolver,
  memberCardsResolver,
  memberCustomEmojiResolver,
  memberIdFromUsernameResolver,
  memberSearchResolver,
  notificationChannelSettingsResolver,
  refreshEmailConfirmation,
  removeBoardStar,
  resendVerificationEmail,
  revokeToken,
  trelloMemberRecap,
  unblockMemberProfileSync,
  updateBoardStar,
  updateCampaign,
  updateCustomBackground,
  updateMarketingOptIn,
  updateMemberColorBlindPref,
  updateMemberProfile,
  updateNotificationChannelSettings,
  updateNotificationEmailFrequency,
  uploadCustomBackground,
  uploadCustomSticker,
} from './resolvers/member';
import {
  notificationGroupsResolver,
  notificationsCountResolver,
  notificationsResolver,
  setAllNotificationsRead,
  setNotificationsRead,
} from './resolvers/notifications';
import {
  addFreeTrial,
  addMemberToOrg,
  addTag,
  bulkDisableWorkspacePowerUp,
  bulkEnableWorkspacePowerUp,
  copyBoardToOrg,
  createEnterpriseJoinRequest,
  createOrganization,
  createOrganizationInviteSecret,
  deactivateMemberForWorkspace,
  deleteEnterpriseJoinRequest,
  deleteOrganization,
  deleteOrganizationInviteSecret,
  deleteOrganizationLogo,
  inviteMemberToJira,
  organizationBoardsResolver,
  organizationCardsResolver,
  organizationCardStatsResolver,
  organizationInviteSecretResolver,
  organizationJiraEligibleMembersResolver,
  organizationLabelNamesResolver,
  organizationMemberCardsResolver,
  organizationNewBillableGuestsResolver,
  organizationOwnedPluginsResolver,
  organizationSlackAssociationResolver,
  organizationStatsResolver,
  reactivateMemberForWorkspace,
  removeMemberFromWorkspace,
  removeOrganizationOrgInviteRestrictDomain,
  startOrganizationExport,
  toggleAutoUpgrade,
  unlinkSlackTeam,
  updateOrganization,
  updateOrganizationAtlassianIntelligenceEnabled,
  updateOrganizationBoardInvitationRestrict,
  updateOrganizationEnterpriseBoardDeleteRestrict,
  updateOrganizationEnterpriseBoardVisibilityRestrict,
  updateOrganizationOrgBoardDeleteRestrict,
  updateOrganizationOrgBoardVisibilityRestrict,
  updateOrganizationOrgInviteRestrict,
  updateOrganizationPermissionLevel,
  updateOrganizationPrivateBoardDeleteRestrict,
  updateOrganizationPrivateBoardVisibilityRestrict,
  updateOrganizationPublicBoardDeleteRestrict,
  updateOrganizationPublicBoardVisibilityRestrict,
  updateOrganizationSlackTeamInvitationRestriction,
  updateOrganizationSlackTeamLinkRestriction,
  updateWorkspaceMemberPermission,
  uploadOrganizationImage,
  workspaceGuestsResolver,
} from './resolvers/organization';
import {
  createOrganizationView,
  deleteOrganizationView,
  updateOrganizationView,
  updateViewInOrganizationView,
} from './resolvers/organizationView';
import {
  addMembersPriceQuotesResolver,
  cancelWorkspacePaidAccount,
  extendTrialPaidSubscription,
  isFreeToAddMembersResolver,
  memberStatementsResolver,
  newSubscriptionListPriceQuotesResolver,
  newSubscriptionPriceQuotesResolver,
  organizationStatementsResolver,
  preAuthorizeWorkspaceCreditCard,
  redeemPromoCode,
  renewalPriceQuotesResolver,
  startWorkspacePaidSubscription,
  updateOrganizationBillingContactDetails,
  updateOrganizationBillingInvoiceDetails,
  updateOrganizationCreditCard,
  updateOrganizationPaidProduct,
  upgradePriceQuotesResolver,
} from './resolvers/paidAccount';
import { assignPersonalProductivityCohort } from './resolvers/personalProductivity';
import {
  addPluginCollaborator,
  createPlugin,
  createPluginListing,
  deletePlugin,
  deletePluginListing,
  deleteSharedPluginData,
  pluginCollaboratorsResolver,
  removePluginCollaborator,
  updatePlugin,
  updatePluginListing,
} from './resolvers/plugin';
import {
  pluginCategoriesResolver,
  publicPluginsResolver,
} from './resolvers/publicPlugin';
import { qrCodeResolver } from './resolvers/qrCode';
import { addReaction, deleteReaction } from './resolvers/reactions';
import {
  accessRequestResolver,
  accessRequestVerificationResolver,
  deleteAccessRequest,
  sendAccessRequest,
} from './resolvers/requestAccess';
import {
  templateCategoriesResolver,
  templateGalleryResolver,
  templateLanguagesResolver,
} from './resolvers/template';
import { unsplashPhotosResolver } from './resolvers/unsplash';
import {
  batchRestResourceResolver,
  restResourceResolver,
} from './restResourceResolver/restResourceResolver';

export const queryMap = {
  action: restResourceResolver,
  accessRequest: accessRequestResolver,
  accessRequestVerification: accessRequestVerificationResolver,
  announcements: announcementsResolver,
  archivedCardsForBoard: archivedCardsForBoardResolver,
  board: restResourceResolver,
  boardActions: boardActionsResolver,
  boardLists: boardListsResolver,
  boards: batchRestResourceResolver,
  boardsSearch: boardsSearchResolver,
  card: restResourceResolver,
  cards: batchRestResourceResolver,
  cardActions: cardActionsResolver,
  checklist: restResourceResolver,
  checklists: batchRestResourceResolver,
  defaultOrganization: defaultOrganizationResolver,
  getAssociatedWorkspacesForMember: getAssociatedWorkspacesForMemberResolver,
  invitationSecret: boardInviteSecretRequestResolver,
  list: restResourceResolver,
  lists: batchRestResourceResolver,
  emailProvider: emailProviderResolver,
  member: restResourceResolver,
  members: batchRestResourceResolver,
  memberActions: memberActionsResolver,
  memberCards: memberCardsResolver,
  memberCustomEmoji: memberCustomEmojiResolver,
  memberIdFromUsername: memberIdFromUsernameResolver,
  memberSearch: memberSearchResolver,
  notificationGroups: notificationGroupsResolver,
  notifications: notificationsResolver,
  notificationsCount: notificationsCountResolver,
  notificationChannelSettings: notificationChannelSettingsResolver,
  organization: restResourceResolver,
  organizations: batchRestResourceResolver,
  organizationBoards: organizationBoardsResolver,
  organizationMemberCards: organizationMemberCardsResolver,
  organizationView: restResourceResolver,
  organizationViews: batchRestResourceResolver,
  organizationInviteSecret: organizationInviteSecretResolver,
  workspaceGuests: workspaceGuestsResolver,
  label: restResourceResolver,
  labels: batchRestResourceResolver,
  enterprise: restResourceResolver,
  enterprises: batchRestResourceResolver,
  publicPlugins: publicPluginsResolver,
  newSubscriptionListPriceQuotes: newSubscriptionListPriceQuotesResolver,
  newSubscriptionPriceQuotes: newSubscriptionPriceQuotesResolver,
  upgradePriceQuotes: upgradePriceQuotesResolver,
  renewalPriceQuotes: renewalPriceQuotesResolver,
  memberStatements: memberStatementsResolver,
  organizationStatements: organizationStatementsResolver,
  addMembersPriceQuotes: addMembersPriceQuotesResolver,
  freeToAddMembers: isFreeToAddMembersResolver,
  search: restResourceResolver,
  plugin: restResourceResolver,
  plugins: batchRestResourceResolver,
  pluginCategories: pluginCategoriesResolver,
  customField: restResourceResolver,
  qrCode: qrCodeResolver,
  unsplashPhotos: unsplashPhotosResolver,
  templateCategories: templateCategoriesResolver,
  templateLanguages: templateLanguagesResolver,
  templateGallery: templateGalleryResolver,
  selfServeExpansionEstimate: selfServeExpansionEstimateResolver,
  pluginApplication: getPluginApplicationResolver,
  seatAutomationHistory: seatAutomationHistoryResolver,
  seatAutomationBlocklistMembers: seatAutomationBlocklistMembersResolver,
  fetchSeatAutomationPreview: seatAutomationPreviewResolver,
  fetchSeatAutomationExport: fetchSeatAutomationExportResolver,
  seatAutomationNextRunDate: seatAutomationNextRunDateResolver,
  getOrder: getOrderResolver,
};

export const resolvers = {
  Query: queryMap,
  Mutation: {
    addBoard,
    addAttachmentToCard,
    addCardComment,
    deleteCardComment,
    updateCardComment,
    deleteOrganization,
    addButlerButton,
    addChecklist,
    addOneTimeMessagesDismissed,
    addMessageDismissed,
    createOrganization,
    copyBoardToOrg,
    updateBoardOrg,
    updateBoardVisibility,
    updateBoardCardCoversPref,
    updateBoardCommentsPref,
    updateBoardHideVotesPref,
    updateBoardInvitationsPref,
    updateBoardSelfJoinPref,
    updateBoardShowCompleteStatusPref,
    updateBoardSwitcherViewsPref,
    updateBoardTemplatePref,
    updateBoardVotingPref,
    dismissAnnouncement,
    deleteChecklist,
    updateChecklistName,
    updateChecklistPos,
    addCheckItem,
    deleteCheckItem,
    addCampaign,
    updateBoard,
    updateBoardMyPrefs,
    updateCampaign,
    updateCheckItem,
    convertCheckItemToCard,
    addBoardStar,
    removeBoardStar,
    updateBoardStar,
    addSavedSearch,
    deleteSavedSearch,
    deactivateEnterpriseMember,
    grantEnterpriseLicense,
    assignMemberEnterpriseAdmin,
    reactivateMember,
    removeEnterpriseMember,
    updateOrganization,
    updateOrganizationPermissionLevel,
    updateOrganizationOrgInviteRestrict,
    removeOrganizationOrgInviteRestrictDomain,
    updateOrganizationAtlassianIntelligenceEnabled,
    updateOrganizationPublicBoardVisibilityRestrict,
    updateOrganizationEnterpriseBoardVisibilityRestrict,
    updateOrganizationOrgBoardVisibilityRestrict,
    updateOrganizationPrivateBoardVisibilityRestrict,
    updateOrganizationPublicBoardDeleteRestrict,
    updateOrganizationEnterpriseBoardDeleteRestrict,
    updateOrganizationOrgBoardDeleteRestrict,
    updateOrganizationPrivateBoardDeleteRestrict,
    updateOrganizationBoardInvitationRestrict,
    updateOrganizationSlackTeamInvitationRestriction,
    updateOrganizationSlackTeamLinkRestriction,
    unlinkSlackTeam,
    enableMemberProfileSync,
    unblockMemberProfileSync,
    deleteOneTimeMessagesDismissed,
    createPlugin,
    deletePlugin,
    enablePlugin,
    updatePlugin,
    createPluginListing,
    updatePluginListing,
    deleteSharedPluginData,
    deletePluginListing,
    addPluginCollaborator,
    removePluginCollaborator,
    acceptDeveloperTerms,
    inviteMemberToJira,
    updateMemberProfile,
    updateMemberColorBlindPref,
    updateCard,
    updateCardAttachment,
    updateCardCover,
    uploadCardCover,
    updateCardDueComplete,
    updateCardLabels,
    updateCardList,
    updateCardName,
    updateCardSubscription,
    updateCardPos,
    resendVerificationEmail,
    addMemberToOrg,
    updateWorkspaceMemberPermission,
    removeMemberFromWorkspace,
    deactivateMemberForWorkspace,
    reactivateMemberForWorkspace,
    archiveAllCards,
    unarchiveListMutation,
    archiveListMutation,
    createList,
    deleteList,
    moveAllCards,
    updateListDatasourceLink,
    updateListDatasourceFilter,
    updateListColor,
    updateListPosition,
    updateListName,
    updateListSubscription,
    createCardTemplate,
    createCard,
    createCardFromFile,
    copyCard,
    archiveCard,
    setPinCard,
    unarchiveCard,
    deleteCard,
    changeCardDueDate,
    updateCardDates,
    updateCustomFieldItem,
    addStickerToCard,
    removeStickerFromCard,
    markAssociatedNotificationsRead,
    addMemberToCard,
    removeMemberFromCard,
    startBoardExport,
    startEnterpriseExport,
    claimOrganization,
    declineOrganizations,
    startOrganizationExport,
    createCustomField,
    deleteCustomField,
    updateCustomField,
    deleteCustomFieldOption,
    addCustomFieldOption,
    updateCustomFieldOption,
    linkEnterpriseWithAtlassianOrganization,
    preAuthorizeWorkspaceCreditCard,
    startWorkspacePaidSubscription,
    extendTrialPaidSubscription,
    updateOrganizationCreditCard,
    updateOrganizationPaidProduct,
    updateOrganizationBillingContactDetails,
    cancelWorkspacePaidAccount,
    updateOrganizationBillingInvoiceDetails,
    redeemPromoCode,
    uploadOrganizationImage,
    deleteOrganizationLogo,
    addFreeTrial,
    toggleAutoUpgrade,
    deleteManagedMemberTokens,
    deleteAllManagedMemberTokens,
    updateEnterpriseApiTokenCreationPermission,
    createDashboardViewTile,
    updateDashboardViewTile,
    deleteDashboardViewTile,
    updateCardRole,
    createOrganizationView,
    updateOrganizationView,
    updateViewInOrganizationView,
    deleteOrganizationView,
    updateCalendarKey,
    updateEmailKey,
    updateEmailPosition,
    messageEmailKey,
    updateCalendarFeedEnabledPref,
    updateBackgroundPref,
    addMemberToBoard,
    removeMemberFromBoard,
    updateBoardMemberPermission,
    addTag,
    deleteTag,
    editTag,
    toggleTag,
    setNotificationsRead,
    setAllNotificationsRead,
    deleteButlerButton,
    sendAccessRequest,
    deleteAccessRequest,
    deleteBoard,
    updateHiddenPluginBoardButtonShortcuts,
    bulkDisableWorkspacePowerUp,
    bulkEnableWorkspacePowerUp,
    createBoardInviteSecret,
    disableBoardInviteSecret,
    createSelfServeExpansion,
    updateDefaultWorkspace,
    createOrganizationInviteSecret,
    deleteOrganizationInviteSecret,
    createEnterpriseJoinRequest,
    deleteEnterpriseJoinRequest,
    addReaction,
    deleteReaction,
    createLabel,
    deleteLabel,
    updateLabel,
    updateMarketingOptIn,
    updateNotificationEmailFrequency,
    updateNotificationChannelSettings,
    createPluginApplication,
    updateApplication,
    dismissCompletedWorkspaceBatches,
    addMemberVoted,
    deleteMemberVoted,
    updateEnterprisePrefsSeatAutomation,
    updateEnterprisePrefsSeatAutomationBlockedMembers,
    assignPersonalProductivityCohort,
    createSeatAutomationExport,
    runSeatAutomation,
    createTransactionAccount,
    copyList,
    updateListLimit,
    sortList,
    createOrder,
    deleteCustomSticker,
    uploadCustomSticker,
    uploadCustomBackground,
    updateCustomBackground,
    removeAttachmentFromCard,
    markCardAsViewed,
    inboxAssistantClassify,
    inboxAssistantExecute,
    deleteCustomBackground,
    updateBoardBackgroundImage,
    refreshEmailConfirmation,
    trelloMemberRecap,
    revokeToken,
  },

  // Custom type resolvers

  Board: {
    export: boardExportResolver,
    exports: boardExportResolver,
    stats: statsResolver,
    dashboardViewTile: boardDashboardViewTileResolver,
    history: boardHistoryResolver,
  },

  Board_History: {
    cardsPerList: getCardsPerList,
    cardsPerLabel: getCardsPerLabel,
    cardsPerMember: getCardsPerMember,
    cardsPerDueDateStatus: getCardsPerDueDateStatus,
  },

  Card: {
    possibleCardRole: possibleCardRoleResolver,
  },

  Enterprise: {
    organizations: enterpriseOrganizationsResolver,
    claimableOrganizations: enterpriseClaimableOrganizationsResolver,
    pendingOrganizations: enterprisePendingOrganizationsResolver,
    defaultOrganization: defaultOrganizationResolver,
    transferrableData: transferrableDataForOrganizationResolver,
    managedMembersWithTokens: managedMembersWithTokensResolver,
    auditlog: auditlogResolver,
    export: getEnterpriseExportResolver,
    memberships: membershipsResolver,
  },

  Member: {
    agreements: memberAgreementsResolver,
    atlassianOrganizations: memberAtlassianOrganizationsResolver,
  },

  Organization: {
    ownedPlugins: organizationOwnedPluginsResolver,
    stats: organizationStatsResolver,
    newBillableGuests: organizationNewBillableGuestsResolver,
    cards: organizationCardsResolver,
    jiraEligibleMembers: organizationJiraEligibleMembersResolver,
    slackAssociation: organizationSlackAssociationResolver,
  },

  Organization_Stats: {
    cards: organizationCardStatsResolver,
    labelNames: organizationLabelNamesResolver,
  },

  Plugin: {
    collaborators: pluginCollaboratorsResolver,
  },
} as Resolvers;
