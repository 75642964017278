import { useCallback, useState, type FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

import { DynamicButton } from '@trello/dynamic-tokens';
import { currentLocale } from '@trello/locale';
import { CloseIcon } from '@trello/nachos/icons/close';
import { Overlay } from '@trello/nachos/overlay';

import { LoadingSpinner } from 'app/src/components/LoadingSpinner';

import styles from './PPBetaVideoModal.less';

const noop = () => {};

const VIDEO_URLS: { [key: string]: string } = {
  cs: 'https://www.loom.com/embed/3336a64d118a4e799d3e1101d0979cb7/?autoplay=true&hideEmbedTopBar=true&muted=false',
  de: 'https://www.loom.com/embed/ccd1f1a9b41d48588f0224cd2c443666/?autoplay=true&hideEmbedTopBar=true&muted=false',
  'en-AU':
    'https://www.loom.com/embed/06f60ec4de6e48c491be87e2f7037b67?sid=874e8886-fee6-4926-8b08-6a02f8e1a30d&autoplay=true&hideEmbedTopBar=true&muted=false',
  'en-GB':
    'https://www.loom.com/embed/06f60ec4de6e48c491be87e2f7037b67?sid=874e8886-fee6-4926-8b08-6a02f8e1a30d&autoplay=true&hideEmbedTopBar=true&muted=false',
  'en-US':
    'https://www.loom.com/embed/06f60ec4de6e48c491be87e2f7037b67?sid=874e8886-fee6-4926-8b08-6a02f8e1a30d&autoplay=true&hideEmbedTopBar=true&muted=false',
  es: 'https://www.loom.com/embed/1fa7527e46904d6baeac46faaa430400/?autoplay=true&hideEmbedTopBar=true&muted=false',
  fi: 'https://www.loom.com/embed/0b4c35c6dba44e40bf8ae1c54915435e/?autoplay=true&hideEmbedTopBar=true&muted=false',
  fr: 'https://www.loom.com/embed/aa298facf6a8470fb23dae2a29d6d998/?autoplay=true&hideEmbedTopBar=true&muted=false',
  hu: 'https://www.loom.com/embed/a94bdee3bc644658985432be81e1b926/?autoplay=true&hideEmbedTopBar=true&muted=false',
  it: 'https://www.loom.com/embed/3c896f3dab4e4fafb1d5413fea9fdab6/?autoplay=true&hideEmbedTopBar=true&muted=false',
  ja: 'https://www.loom.com/embed/3dbfb71b95ec4dbab64d5da2b9eae86e/?autoplay=true&hideEmbedTopBar=true&muted=false',
  nb: 'https://www.loom.com/embed/60743c652fbc441e9c10a0332a38dab5/?autoplay=true&hideEmbedTopBar=true&muted=false',
  nl: 'https://www.loom.com/embed/8c70806f72894ee6b07e5ade8a78dc90/?autoplay=true&hideEmbedTopBar=true&muted=false',
  pl: 'https://www.loom.com/embed/e54f613537d044999cacd61ea4d6dc8a/?autoplay=true&hideEmbedTopBar=true&muted=false',
  'pt-BR':
    'https://www.loom.com/embed/cb9c558a024d4ce5995df100c9e9dbea/?autoplay=true&hideEmbedTopBar=true&muted=false',
  ru: 'https://www.loom.com/embed/8c4f6268554d4d26ac066d7cf3a0ff13/?autoplay=true&hideEmbedTopBar=true&muted=false',
  sv: 'https://www.loom.com/embed/ab61060c7cdf4c3381ff87c082c54760/?autoplay=true&hideEmbedTopBar=true&muted=false',
  th: 'https://www.loom.com/embed/16340ca807d04b3dbc5b5e2da5fed766/?autoplay=true&hideEmbedTopBar=true&muted=false',
  tr: 'https://www.loom.com/embed/4b90ea23c92a4be685f43a55f11648d8/?autoplay=true&hideEmbedTopBar=true&muted=false',
  uk: 'https://www.loom.com/embed/df8d5efcc9574d18a0dff0bcd11bdf9e/?autoplay=true&hideEmbedTopBar=true&muted=false',
  vi: 'https://www.loom.com/embed/2036559206954ad5a45ac2070e0ad242/?autoplay=true&hideEmbedTopBar=true&muted=false',
  'zh-Hans':
    'https://www.loom.com/embed/637ae748a461419c8eeb1d2851a2d099/?autoplay=true&hideEmbedTopBar=true&muted=false',
  'zh-Hant':
    'https://www.loom.com/embed/fc5a9ec6df174264ab6350b70a4141cd/?autoplay=true&hideEmbedTopBar=true&muted=false',
};

export const PPBetaVideoModal: FunctionComponent<{
  onClose: () => void;
}> = ({ onClose }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(true);

  const videoSrc = VIDEO_URLS[currentLocale] || VIDEO_URLS['en-US'];

  const onIframeLoad = useCallback(() => {
    setIsLoading(false);
  }, []);

  return (
    <Overlay
      className={styles.overlayOverrides}
      closeOnEscape={false}
      onClose={noop}
    >
      <div className={styles.overlay}>
        {isLoading && <LoadingSpinner />}
        {/* eslint-disable-next-line @eslint-react/dom/no-missing-iframe-sandbox */}
        <iframe
          src={videoSrc}
          frameBorder="0"
          allowFullScreen
          className={styles.videoIframe}
          title="New Trello Loom Video"
          onLoad={onIframeLoad}
        ></iframe>
        <DynamicButton
          className={styles.closeButton}
          iconBefore={<CloseIcon size="medium" />}
          onClick={onClose}
          aria-label={intl.formatMessage({
            id: 'templates.dialog_close_button.close-dialog-label',
            defaultMessage: 'Close dialog',
            description: 'Aria label for the close dialog button',
          })}
        />
      </div>
    </Overlay>
  );
};
