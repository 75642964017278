import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { TrelloCard, TrelloMemberNonPublicData } from '../generated';

export const formatTrelloCardMemberData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const members = readField<TrelloCard['members']>('members', trelloCardRef);

  if (members?.edges === undefined || members.edges === null) {
    return { edges: [] };
  }

  const formattedEdges = members.edges
    .map((edge) => {
      const memberRef = edge?.node;
      if (memberRef === null || memberRef === undefined) {
        return null;
      }

      const objectId = readField<string>('objectId', memberRef);
      const username = readField<string>('username', memberRef);
      if (objectId === undefined || username === undefined) {
        return null;
      }
      const avatarUrl = readField<string>('avatarUrl', memberRef);
      const fullName = readField<string>('fullName', memberRef);
      const initials = readField<string>('initials', memberRef);
      const nonPublicDataRef = readField<TrelloMemberNonPublicData>(
        'nonPublicData',
        memberRef,
      );

      const nonPublicData = {
        avatarUrl: readField<string>('avatarUrl', nonPublicDataRef),
        fullName: readField<string>('fullName', nonPublicDataRef),
        initials: readField<string>('initials', nonPublicDataRef),
      };

      return {
        node: {
          objectId,
          avatarUrl,
          fullName,
          initials,
          username,
          nonPublicData,
        },
      };
    })
    .filter((edge) => edge !== null);

  return { edges: formattedEdges };
};
