import type { TrelloCardFields } from './mapTrelloCardDataToCard';

export const mapTrelloCardMemberIdsToIdMembers = (data: TrelloCardFields) => {
  const memberEdges = data?.members?.edges;
  if (!memberEdges || memberEdges.length === 0) {
    return [];
  }
  const memberObjectIds = memberEdges
    .filter((edge) => {
      return edge.node !== undefined;
    })
    .map((edge) => {
      const node = edge.node;
      return node.objectId;
    });
  return memberObjectIds;
};
