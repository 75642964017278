import type {
  Card_Cover_Brightness,
  Card_Cover_Color,
  Card_Cover_Size,
} from '../generated';
import type { TrelloCardFields } from './mapTrelloCardDataToCard';

export const mapTrelloCardCoverDataToCardCover = (
  trelloCardData: TrelloCardFields,
) => {
  const coverPreviewEdges = trelloCardData?.cover?.previews?.edges;
  const mapCoverPreviewFields = () => {
    if (!coverPreviewEdges || coverPreviewEdges.length === 0) {
      return null;
    }
    const previews = coverPreviewEdges
      .filter((edge) => {
        const node = edge.node;
        return (
          node.bytes !== undefined &&
          node.height !== undefined &&
          node.scaled !== undefined &&
          node.url !== undefined &&
          node.width !== undefined
        );
      })
      .map((edge) => {
        const node = edge.node;
        return {
          __typename: 'Card_Cover_Scaled' as const,
          id: node.objectId as string,
          bytes: node.bytes as number,
          height: node.height as number,
          scaled: node.scaled as boolean,
          url: node.url as string,
          width: node.width as number,
        };
      });
    return previews.length > 0 ? previews : null;
  };
  const coverPreviewFields = mapCoverPreviewFields();
  return {
    cover: {
      __typename: 'Card_Cover' as const,
      color:
        (trelloCardData.cover?.color?.toLowerCase() as Card_Cover_Color) ??
        null,
      size:
        (trelloCardData.cover?.size?.toLowerCase() as Card_Cover_Size) ?? null,
      idAttachment: trelloCardData.cover?.attachment?.objectId ?? null,
      idPlugin: trelloCardData.cover?.powerUp?.objectId ?? null,
      idUploadedBackground:
        trelloCardData.cover?.uploadedBackground?.objectId ?? null,
      brightness:
        (trelloCardData.cover?.brightness?.toLowerCase() as Card_Cover_Brightness) ??
        null,
      edgeColor: trelloCardData.cover?.edgeColor ?? null,
      sharedSourceUrl: trelloCardData.cover?.sharedSourceUrl ?? null,
      scaled: coverPreviewFields,
    },
  };
};
