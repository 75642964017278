import { type InMemoryCache } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { sendErrorEvent } from '@trello/error-reporting';

import type {
  TrelloCustomField,
  TrelloCustomFieldItemValueInfo,
} from '../generated';
import { type TrelloCustomFieldItem } from '../generated';
import {
  isBool,
  isNumber,
  isObjectId,
  isString,
  nullOrBool,
  nullOrNumber,
  nullOrString,
} from '../plannerCardDataMapping/validateHelpers';
import type { RecursivePartial, TargetModel } from './cacheModelTypes';
import { getObjectIdFromCacheObject } from './getObjectIdFromCacheObject';
import { syncNativeNestedObjectToRest } from './syncNativeNestedObjectToRest';
import { syncNativeToRestScalars } from './syncNativeToRestScalars';

const scalarFieldMappings = {
  customField: {
    fetchValue: (
      incoming: RecursivePartial<TrelloCustomFieldItem>,
      readField: ReadFieldFunction,
    ) => {
      const customField = readField<TrelloCustomField>('customField', incoming);
      if (customField) {
        return readField<string>('objectId', customField);
      }
      // If custom field is null or undefined, we just return that value for idCustomField
      return customField;
    },
    validate: isObjectId,
    key: 'idCustomField',
  },
};

/* For syncing dropdown custom fields */
const dropdownValueFieldMappings = {
  // In this case, the schema is not great and 'id' doesn't mean ARI but objectId :/
  id: { validate: (val: unknown) => val === null || isObjectId(val) },
};

const generateDropdownCustomFieldItemValueFragment = (field: string) => {
  return `fragment CustomFieldItemValueWrite on CustomFieldItem {
    id
    idValue
    value
  }`;
};

const generateDropdownCustomFieldItemValueData = (
  id: string,
  field: string,
  value: unknown,
) => {
  return {
    __typename: 'CustomFieldItem',
    id,
    idValue: value,
    value: null,
  };
};

/* For syncing non-dropdown custom fields */
const valueFieldMappings = {
  checked: {
    validate: nullOrBool,
    transform: (val: boolean | null) => (isBool(val) ? val.toString() : val),
  },
  date: { validate: nullOrString },
  number: {
    validate: nullOrNumber,
    transform: (val: number | null) => (isNumber(val) ? val.toString() : val),
  },
  text: { validate: nullOrString },
  // For non-dropdown custom fields, value.id should always be null
  id: {
    validate: (val: unknown) => val === null,
    generateFragment:
      () => `fragment CustomFieldItemValueidValueWrite on CustomFieldItem {
      id
      idValue
    }`,
    generateData: (id: string, value: unknown) => ({
      __typename: 'CustomFieldItem',
      id,
      idValue: value,
    }),
  },
};

const generateCustomFieldItemValueFragment = (field: string) => {
  return `fragment CustomFieldItemValue${field}Write on CustomFieldItem {
    id
    value {
      ${field}
    }
  }`;
};

const generateCustomFieldItemValueData = (
  id: string,
  field: string,
  value: unknown,
) => {
  return {
    __typename: 'CustomFieldItem',
    id,
    value: {
      __typename: 'CustomFieldItem_Value',
      [field]: value,
    },
  };
};

/**
 * Given native TrelloCustomFieldItem data, writes all custom
 * field item data to the CustomFieldItem model in the Apollo Cache
 * @param incoming A partial TrelloBoard model
 * @param cache The cache to write to
 * @param readField A function to read fields from cache references
 */
export const syncTrelloCustomFieldItemToCustomFieldItem = (
  incoming: RecursivePartial<TrelloCustomFieldItem>,
  cache: InMemoryCache,
  readField: ReadFieldFunction,
) => {
  try {
    const customFieldItemId = getObjectIdFromCacheObject(incoming, readField);
    const customFieldItem: TargetModel = {
      type: 'CustomFieldItem',
      id: customFieldItemId,
    };

    syncNativeToRestScalars<TrelloCustomFieldItem>(
      customFieldItem,
      scalarFieldMappings,
      incoming,
      cache,
      readField,
    );

    const value = readField<TrelloCustomFieldItemValueInfo>('value', incoming);
    if (value) {
      const idValue = readField<string | null>('id', value);
      if (isString(idValue)) {
        // This is a dropdown custom field and we want to avoid writing to any
        // fields inside CustomFieldItem.value, we just want it to be null
        syncNativeNestedObjectToRest(
          customFieldItem,
          dropdownValueFieldMappings,
          generateDropdownCustomFieldItemValueFragment,
          generateDropdownCustomFieldItemValueData,
          // We've got a stupid server-side bug here where we created an id field on
          // TrelloCustomFieldItemValueInfo but didn't make it an ARI
          // So instead of this being a StoreObject it's a Reference that looks like this
          // { __ref: 'TrelloCustomFieldItemValueInfo:67db1fa314eb7019547c5a3a' }
          // Since syncNativeNestedObjectToRest relies on Object.keys(), having a ref
          // messes up the nested object syncing. So we pass through our own StoreObject
          { __typename: 'CustomFieldItem', id: idValue },
          cache,
        );
      } else {
        // This is a number/text/date/checkbox custom field and idValue should be null
        syncNativeNestedObjectToRest(
          customFieldItem,
          valueFieldMappings,
          generateCustomFieldItemValueFragment,
          generateCustomFieldItemValueData,
          value,
          cache,
        );
      }
    }
  } catch (err) {
    sendErrorEvent(err);
  }
};
