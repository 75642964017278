import type { TrelloBoardAll } from './mapTrelloCardDataToCard';
import { isString, nullOrString } from './validateHelpers';

export const validateTrelloBoardData = (inputBoard: {
  objectId?: string | null;
  workspace?: {
    objectId?: string | null;
  } | null;
  enterprise?: { objectId?: string | null } | null;
  powerUps?: {
    edges: Array<{
      objectId?: string | null;
      node: {
        objectId?: string | null;
      };
    }>;
  } | null;
}): inputBoard is TrelloBoardAll => {
  const { objectId, workspace, enterprise, powerUps } = inputBoard;
  if (powerUps === undefined) {
    return false;
  }

  return (
    nullOrString(objectId) &&
    (workspace === null || isString(workspace?.objectId)) &&
    (enterprise === null || isString(enterprise?.objectId)) &&
    Array.isArray(powerUps?.edges) &&
    powerUps.edges.every((edge) => {
      return edge.node.objectId && isString(edge.objectId);
    })
  );
};
