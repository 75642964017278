import { SharedState } from '@trello/shared-state';

interface TryBetaSpotlightSharedState {
  isOpen: boolean;
}

export const defaultTryBetaSpotlightSharedState: TryBetaSpotlightSharedState = {
  isOpen: false,
};

export const tryBetaSpotlightSharedState = new SharedState(
  defaultTryBetaSpotlightSharedState,
);
