// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* stylelint-disable design-system/ensure-design-token-usage */
.duNi0IN977st0m {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 90dvw;
  height: 90dvh;
  background: #000000;
}
.w2wICwxeHlD40R > div {
  margin: 0;
}
.w2wICwxeHlD40R {
  scrollbar-gutter: initial;
}
.kfcsFpxF82mSq7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bt_sRiRwuOP5g0 {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 16px;
  background: #0c66e4;
  color: #ffffff;
}
.bt_sRiRwuOP5g0:hover {
  background: #0055cc;
  color: #ffffff;
}
.bt_sRiRwuOP5g0:active {
  background: #09326c;
  color: #ffffff;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `duNi0IN977st0m`,
	"overlayOverrides": `w2wICwxeHlD40R`,
	"videoIframe": `kfcsFpxF82mSq7`,
	"closeButton": `bt_sRiRwuOP5g0`
};
export default ___CSS_LOADER_EXPORT___;
