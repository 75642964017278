import confetti from 'canvas-confetti';
import emojiRegex from 'emoji-regex-xs';
import { random } from 'underscore';

import { dangerouslyGetFeatureGateSync } from '@trello/feature-gate-client';

// eslint-disable-next-line @trello/no-module-logic
const regex = emojiRegex();

const EMOJI_CONFETTI_SIZE = 3;

interface ConfettiOptions {
  x: number;
  y: number;
  angle?: number;
  spread?: number;
  particleCount?: number;
  name?: string;
}

export const fireConfetti = ({
  x,
  y,
  angle,
  spread,
  particleCount,
  name = '',
}: ConfettiOptions) => {
  const isEnabled = dangerouslyGetFeatureGateSync('trello_emoji_confetti');

  const emojisInName = name.match(regex);

  const confettiConfig = {
    angle: angle ?? random(55, 125),
    spread: spread ?? random(50, 70),
    origin: { x, y },
    disableForReducedMotion: true,
  };

  confetti({
    ...confettiConfig,
    particleCount: particleCount ?? random(40, 75),
  });

  if (emojisInName?.length && isEnabled) {
    const shapes = emojisInName.map((emoji) =>
      confetti.shapeFromText({ text: emoji, scalar: EMOJI_CONFETTI_SIZE }),
    );

    /**
     * Separate calls of confetti gives us more control over particle sizes,
     * using the scalar property.
     * Both confetti calls use the same positioning properties, so they will
     * appear evenly mixed together as one big firing of confetti on screen
     */
    confetti({
      ...confettiConfig,
      particleCount: particleCount ?? random(20, 37),
      scalar: EMOJI_CONFETTI_SIZE,
      shapes,
    });
  }
};

export const resetConfetti = confetti.reset;
