import { isBool, isNumber, isString, nullOrString } from './validateHelpers';

export type TrelloCardBoardFields = {
  objectId: string | null;
  name?: string | null;
  prefs?: {
    background: {
      brightness?: string | null;
      color?: string | null;
      image?: string | null;
      imageScaled?:
        | {
            height?: number | null;
            url?: string | null;
            width?: number | null;
          }[]
        | null;
      topColor?: string | null;
    };
    showCompleteStatus?: boolean | null;
    cardAging?: string | null;
    cardCovers?: boolean | null;
  } | null;
  url?: string | null;
};

export const validateTrelloCardBoardData = (
  boardFields: TrelloCardBoardFields | undefined,
): boolean => {
  if (boardFields === undefined) {
    return false;
  }

  const background = boardFields.prefs?.background;
  const showCompleteStatus = boardFields.prefs?.showCompleteStatus;
  if (background === undefined) {
    return false;
  }

  return (
    (boardFields.prefs?.cardAging === 'regular' ||
      boardFields.prefs?.cardAging === 'pirate') &&
    isString(boardFields.objectId) &&
    isString(boardFields.name) &&
    isString(boardFields.url) &&
    ((isString(background.brightness) &&
      (background.brightness === 'light' ||
        background.brightness === 'dark' ||
        background.brightness === 'unknown')) ||
      background.brightness === null) &&
    nullOrString(background.image) &&
    nullOrString(background.color) &&
    isString(background.topColor) &&
    (isBool(showCompleteStatus) || showCompleteStatus === null) &&
    isBool(boardFields.prefs.cardCovers) &&
    isString(boardFields.url) &&
    ((Array.isArray(background.imageScaled) &&
      background.imageScaled.every(
        (image) =>
          isNumber(image.height) &&
          isString(image.url) &&
          isNumber(image.width),
      )) ||
      background.imageScaled === null)
  );
};
