export type List = {
  objectId: string | null;
  board?: { objectId: string | null };
  name?: string | null;
};

export const validateTrelloListData = (list: List | undefined): boolean => {
  if (list === undefined) {
    return false;
  }
  return (
    typeof list.objectId === 'string' &&
    typeof list.name === 'string' &&
    typeof list?.board === 'object' &&
    typeof list?.board.objectId === 'string'
  );
};
