import type { TrelloCardFields } from './mapTrelloCardDataToCard';

export const mapTrelloCardCustomFieldItems = (
  trelloCardData: TrelloCardFields,
) => {
  const customFieldItems = trelloCardData.customFieldItems.edges.map((edge) => {
    const customFieldItem = edge.node;
    return {
      __typename: 'CustomFieldItem' as const,
      id: customFieldItem.objectId,
      idCustomField: customFieldItem.customField.objectId,
      idValue: customFieldItem.value.id,
      value: customFieldItem.value.id
        ? null
        : {
            checked: customFieldItem.value.checked?.toString() || null,
            date: customFieldItem.value.date,
            number: customFieldItem.value.number?.toString() || null,
            text: customFieldItem.value.text,
            __typename: 'CustomFieldItem_Value' as const,
          },
    };
  });

  return customFieldItems;
};

export const mapTrelloCardCustomFieldItemsToCustomFields = (
  trelloCardData: TrelloCardFields,
) => {
  let customFields;
  if (trelloCardData.customFieldItems.edges.length) {
    // only try to sync custom fields if there are customfield items for the card
    customFields = trelloCardData.customFieldItems.edges.map((myEdge) => {
      const customField = myEdge.node.customField;
      const customFieldId = customField.objectId;
      return {
        id: customFieldId,
        display: {
          cardFront: customField.display.cardFront,
          __typename: 'CustomField_Display' as const,
        },
        name: customField.name,
        options: customField.options.map((option) => ({
          __typename: 'CustomField_Option' as const,
          id: option.objectId,
          idCustomField: customFieldId,
          color: option.color,
          pos: option.position,
          value: {
            __typename: 'CustomField_Option_Value' as const,
            text: option.value.text,
          },
        })),
        pos: customField.position,
        type: customField.type,
        __typename: 'CustomField' as const,
      };
    });
  }
  return customFields;
};
