import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type CardFrontBoardCustomFieldsFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id'>
  & { customFields: Array<(
    { __typename: 'CustomField' }
    & Pick<Types.CustomField, 'id' | 'name' | 'pos' | 'type'>
    & { display: (
      { __typename: 'CustomField_Display' }
      & Pick<Types.CustomField_Display, 'cardFront'>
    ), options?: Types.Maybe<Array<(
      { __typename: 'CustomField_Option' }
      & Pick<Types.CustomField_Option, 'id' | 'color' | 'idCustomField' | 'pos'>
      & { value: (
        { __typename: 'CustomField_Option_Value' }
        & Pick<Types.CustomField_Option_Value, 'text'>
      ) }
    )>> }
  )> }
);

export const CardFrontBoardCustomFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardFrontBoardCustomFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customFields' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'display' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cardFront' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'options' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idCustomField' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'pos' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'value' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'text' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pos' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseCardFrontBoardCustomFieldsFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      CardFrontBoardCustomFieldsFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseCardFrontBoardCustomFieldsFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<CardFrontBoardCustomFieldsFragment>,
    'data'
  > {
  data?: CardFrontBoardCustomFieldsFragment;
}

export const useCardFrontBoardCustomFieldsFragment = ({
  from,
  returnPartialData,
  ...options
}: UseCardFrontBoardCustomFieldsFragmentOptions): UseCardFrontBoardCustomFieldsFragmentResult => {
  const result = Apollo.useFragment<CardFrontBoardCustomFieldsFragment>({
    ...options,
    fragment: CardFrontBoardCustomFieldsFragmentDoc,
    fragmentName: 'CardFrontBoardCustomFields',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as CardFrontBoardCustomFieldsFragment };
};
