import type { Reference, StoreObject } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { isObjectId } from '../plannerCardDataMapping/validateHelpers';
import { InvalidIdError, MissingIdError } from './cacheSyncingErrors';

/**
 * Given a Reference to or a StoreObject representing any object
 * in the cache, extracts the object ID and validates it. The function
 * first tries to derive the object ID from the id field. If the
 * id field is missing, then it tries to read the objectId field
 * of the object.
 * @param incoming A Reference or StoreObject
 * @param readField A readField function
 * @returns A stirng objectId
 * @throws {MissingIdError} If the node does not have an id or objectId field
 * @throws {InvalidIdError} If the objectId derived from the id or objectId field is not valid
 */
export const getObjectIdFromCacheObject = (
  incoming: Reference | StoreObject,
  readField: ReadFieldFunction,
): string => {
  const ari = readField<string>('id', incoming);
  const type = readField<string>('__typename', incoming)!;

  // If the id field is missing, fall back to the objectId field
  const objectId = ari
    ? ari.split('/').pop()
    : readField<string>('objectId', incoming);

  if (objectId === undefined) {
    throw new MissingIdError(type);
  }

  if (!isObjectId(objectId)) {
    throw new InvalidIdError(type, ari);
  }

  return objectId;
};
