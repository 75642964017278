import { useMemo } from 'react';

import { isEmbeddedDocument } from '@trello/browser';
import { useFeatureGate } from '@trello/feature-gate-client';
import type { RouteIdType } from '@trello/router';
import { RouteId, useIsActiveRoute } from '@trello/router';

const HEADER_ROUTES_BLOCKLIST = new Set<RouteIdType>([
  RouteId.CREATE_FIRST_BOARD,
  RouteId.WELCOME_TO_TRELLO,
  RouteId.REDEEM,
]);

const FULL_SCREEN_WORKSPACE_ROUTES = new Set<RouteIdType>([
  RouteId.ORGANIZATION_BY_ID,
  RouteId.ORGANIZATION_BOARDS,
  RouteId.ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_GUESTS,
  RouteId.ORGANIZATION_REQUESTS,
  RouteId.ACCOUNT,
  RouteId.ORGANIZATION_EXPORT,
  RouteId.ORGANIZATION_POWER_UPS,
  RouteId.WORKSPACE_BILLING,
  RouteId.ORGANIZATION_FREE_TRIAL,
  RouteId.WORKSPACE_VIEW,
  RouteId.ORGANIZATION_TABLES,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW,
  RouteId.OLD_ORGANIZATION_TABLES,
  RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW,
]);

const MEMBERS_SETTINGS_ROUTES = new Set<RouteIdType>([
  RouteId.MEMBER_PROFILE_SECTION,
  RouteId.MEMBER_ACTIVITY,
  RouteId.MEMBER_CARDS,
  RouteId.MEMBER_ACCOUNT,
]);

const BOARD_OR_CARD_ROUTES = new Set<RouteIdType>([
  RouteId.BOARD,
  RouteId.CARD,
]);

/** The Header should be hidden on certain routes. */
export const useShouldRenderHeader = () => {
  const { value: isM2WorkspaceNavEnabled } = useFeatureGate(
    'billplat_m2_workspace_pages',
  );
  const { value: isBillplatWorkspaceSettingsEnabled } = useFeatureGate(
    'billplat_workspace_settings_navigation',
  );

  let blocklist = new Set(HEADER_ROUTES_BLOCKLIST);
  if (isM2WorkspaceNavEnabled) {
    blocklist = new Set([...blocklist, ...FULL_SCREEN_WORKSPACE_ROUTES]);
  }
  if (isBillplatWorkspaceSettingsEnabled) {
    blocklist = new Set([...blocklist, ...MEMBERS_SETTINGS_ROUTES]);
  }

  const isRouteThatHidesHeader = useIsActiveRoute(blocklist);
  const isBoardOrCardRoute = useIsActiveRoute(BOARD_OR_CARD_ROUTES);
  const isEmbeddedOnBoard = useMemo(
    () => isEmbeddedDocument() && isBoardOrCardRoute,
    [isBoardOrCardRoute],
  );

  return !isRouteThatHidesHeader && !isEmbeddedOnBoard;
};
