import type { InMemoryCache } from '@apollo/client';
import { gql } from 'graphql-tag';

import { sendErrorEvent } from '@trello/error-reporting';

import type { TargetModel } from './cacheModelTypes';
import { InvalidValueError } from './cacheSyncingErrors';

/**
 * We can probably extract these types to a shared types file. I'm pretty confident
 * that this Mapping/FieldMapping interface will hold up for other syncing functions
 * as well but it's probably fine to keep each file's types localized for now.
 */

type ObjectMapping = {
  /** A function that validates that this value will not throw an error when written to the cache */
  validate: (value: unknown) => boolean;
  /** An optional key name to write to in the cache (e.g. role --> cardRole) */
  key?: string | null;
  /** An optional transformation to perform on the value before writing it to the cache */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform?: (value: any) => unknown;
  /** An optional function to generate a custom fragment for writing this field to the cache */
  generateFragment?: () => string;
  /** An optional function to generate a custom data for writing this field to the cache */
  generateData?: (id: string, value: unknown) => Record<string, unknown>;
};

export type NestedObjectFieldMapping = Record<string, ObjectMapping>;

/**
 * Given native GraphQL data, writes all scalar fields on the nested
 * model to the Apollo Cache
 * @param model The {@link TargetModel} to write to
 * @param fieldMapping A mapping of field names to {@link ObjectMapping} objects
 * @param generateNestedFragment A function that returns a GraphQL fragment string for the nested field
 * @param generateNestedObjectData A function that returns the nested object data to write
 * @param incoming A partial nested model containing the data to write
 * @param cache The Apollo cache instance to write to
 */
export const syncNativeNestedObjectToRest = <T>(
  model: TargetModel,
  fieldMapping: NestedObjectFieldMapping,
  generateNestedFragment: (field: string) => string,
  generateNestedObjectData: (
    id: string,
    field: string,
    value: unknown,
  ) => Record<string, unknown>,
  incoming: (Partial<T> & { __typename: string }) | null | undefined,
  cache: InMemoryCache,
) => {
  if (!incoming || !model.id) {
    return;
  }

  for (const [fieldName, value] of Object.entries(incoming)) {
    if (fieldMapping[fieldName]) {
      const { key, validate, transform, generateFragment, generateData } =
        fieldMapping[fieldName];
      const mappedKey = key ?? fieldName;

      if (validate(value)) {
        const mappedValue = transform?.(value) ?? value;

        const fragment = generateFragment
          ? generateFragment()
          : generateNestedFragment(mappedKey);
        const data = generateData
          ? generateData(model.id, mappedValue)
          : generateNestedObjectData(model.id, mappedKey, mappedValue);

        cache.writeFragment({
          id: cache.identify({ __typename: model.type, id: model.id }),
          fragment: gql(fragment),
          data,
        });
      } else if (value !== undefined && validate(value) === false) {
        sendErrorEvent(
          new InvalidValueError(incoming.__typename, mappedKey, value),
        );
      }
    }
  }
};
