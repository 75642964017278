import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { TrelloCard, TrelloCardDueInfo } from '../generated';

export const formatTrelloCardDueFields = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const dueInfoRef = readField<TrelloCardDueInfo>('due', trelloCardRef);
  const at = readField<string>('at', dueInfoRef);

  return {
    at,
  };
};
