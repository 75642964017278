import type { InMemoryCache } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import { sendErrorEvent } from '@trello/error-reporting';

import type {
  TrelloBoard,
  TrelloEnterprise,
  TrelloScaleProps,
  TrelloWorkspace,
} from '../generated';
import {
  isBool,
  isEnumString,
  isNumber,
  isString,
  nullOrString,
} from '../plannerCardDataMapping/validateHelpers';
import type { RecursivePartial, TargetModel } from './cacheModelTypes';
import { getObjectIdFromCacheObject } from './getObjectIdFromCacheObject';
import { syncListsOfModelRefs } from './syncListsOfModelRefs';
import {
  syncNativeNestedObjectArray,
  type NestedObjectArrayFieldMapping,
} from './syncNativeNestedObjectArray';
import {
  syncNativeNestedObjectToRest,
  type NestedObjectFieldMapping,
} from './syncNativeNestedObjectToRest';
import { syncNativeToRestScalars } from './syncNativeToRestScalars';
import { syncNestedModelReference } from './syncNestedModelReference';

export const fieldMappings = {
  name: { validate: isString },
  url: { validate: isString },
};

export const boardPrefsFieldMapping: NestedObjectFieldMapping = {
  cardAging: {
    validate: (value) =>
      isString(value) && ['pirate', 'regular'].includes(value),
  },

  showCompleteStatus: {
    validate: isBool,
  },
};

const nestedListMappings = {
  powerUps: { type: 'BoardPlugin' as const, key: 'boardPlugins' },
  customFields: { type: 'CustomField' as const },
};

export const boardPrefsBackgroundFieldMapping: NestedObjectFieldMapping = {
  bottomColor: {
    validate: isString,
    key: 'backgroundBottomColor',
  },
  brightness: {
    validate: (value) => isEnumString(value, ['light', 'dark', 'unknown']),
    key: 'backgroundBrightness',
  },
  color: {
    validate: nullOrString,
    key: 'backgroundColor',
  },
  image: {
    validate: nullOrString,
    key: 'backgroundImage',
  },
  tile: {
    validate: isBool,
    key: 'backgroundTile',
  },
  topColor: {
    validate: isString,
    key: 'backgroundTopColor',
  },
};

export const generateBoardPrefsFragment = (field: string) => {
  return `fragment Board_Prefs${field} on Board {
    id
    prefs {
      ${field}
    }
  }`;
};

export const generateBoardPrefsData = (
  id: string,
  field: string,
  value: unknown,
) => {
  return {
    __typename: 'Board',
    id,
    prefs: {
      __typename: 'Board_Prefs',
      [field]: value,
    },
  };
};

export const backgroundImageScaledFieldMapping: NestedObjectArrayFieldMapping<TrelloScaleProps> =
  {
    height: {
      validate: isNumber,
    },
    width: {
      validate: isNumber,
    },
    url: {
      validate: isString,
    },
  };

export const generateBackgroundImageScaledFragment = () => {
  return `fragment BackgroundImageScaledWrite on Board {
    id
    prefs {
      backgroundImageScaled
    }
  }`;
};

export const generateBackgroundImageScaledData = (
  id: string | undefined,
  value: unknown,
) => {
  return {
    __typename: 'Board',
    id,
    prefs: {
      __typename: 'Board_Prefs',
      backgroundImageScaled: Array.isArray(value)
        ? value.map((obj) => ({
            ...obj,
            __typename: 'Board_Prefs_BackgroundImageScaled',
          }))
        : value,
    },
  };
};

/**
 * Given native TrelloBoard data, writes all board data to the Board model
 * in the Apollo Cache
 * @param incoming A partial TrelloBoard model
 * @param cache The cache to write to
 */
export const syncTrelloBoardToBoard = (
  incoming: RecursivePartial<TrelloBoard>,
  cache: InMemoryCache,
  readField: ReadFieldFunction,
) => {
  try {
    const boardId = getObjectIdFromCacheObject(incoming, readField);
    const board: TargetModel = { type: 'Board', id: boardId };
    syncNativeToRestScalars(board, fieldMappings, incoming, cache, readField);

    syncListsOfModelRefs(board, nestedListMappings, incoming, cache, readField);

    const prefs = readField<TrelloBoard['prefs']>('prefs', incoming);

    if (prefs) {
      syncNativeNestedObjectToRest<TrelloBoard['prefs']>(
        board,
        boardPrefsFieldMapping,
        generateBoardPrefsFragment,
        generateBoardPrefsData,
        prefs,
        cache,
      );

      const background = readField<TrelloBoard['prefs']['background']>(
        'background',
        prefs,
      );
      if (background) {
        syncNativeNestedObjectToRest(
          board,
          boardPrefsBackgroundFieldMapping,
          generateBoardPrefsFragment,
          generateBoardPrefsData,
          background,
          cache,
        );

        const backgroundImageScaled = readField<
          NonNullable<TrelloBoard['prefs']['background']>['imageScaled']
        >('imageScaled', background);
        syncNativeNestedObjectArray<TrelloScaleProps>(
          board,
          backgroundImageScaledFieldMapping,
          generateBackgroundImageScaledFragment,
          generateBackgroundImageScaledData,
          backgroundImageScaled,
          cache,
          { shouldMapNullFieldToNull: true },
        );
      }
    }

    const workspace = readField<TrelloWorkspace>('workspace', incoming);
    if (workspace) {
      const workspaceId = getObjectIdFromCacheObject(workspace, readField);
      syncNestedModelReference(
        board,
        {
          model: { id: workspaceId, type: 'Organization' },
          fieldName: 'organization',
          idFieldName: 'idOrganization',
        },
        cache,
      );
    }

    const enterprise = readField<TrelloEnterprise>('enterprise', incoming);
    if (enterprise) {
      const enterpriseId = getObjectIdFromCacheObject(enterprise, readField);
      syncNestedModelReference(
        board,
        {
          model: { id: enterpriseId, type: 'Enterprise' },
          fieldName: 'enterprise',
          idFieldName: 'idEnterprise',
        },
        cache,
      );
    }
  } catch (err) {
    sendErrorEvent(err);
  }
};
