import { InMemoryCache } from '@apollo/client';

import { fragmentRegistry } from './fragments/fragmentRegistry';
import { typePolicies } from './typePolicies';

// eslint-disable-next-line @trello/no-module-logic
export const cache = new InMemoryCache({
  typePolicies,
  fragments: fragmentRegistry,
  resultCacheMaxSize: 200000,
});
