import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type {
  TrelloCard,
  TrelloCardLimit,
  TrelloCardLimits,
  TrelloLimitProps,
} from '../generated';

export const formatTrelloCardLimitsData = (
  readField: ReadFieldFunction,
  trelloCardRef: Readonly<TrelloCard> | undefined,
) => {
  const limitsRef = readField<TrelloCardLimits>('limits', trelloCardRef);
  const stickersRef = readField<TrelloCardLimit>('stickers', limitsRef);
  const perCardRef = readField<TrelloLimitProps>('perCard', stickersRef);
  const disableAt = readField<number>('disableAt', perCardRef);

  return {
    stickers: {
      perCard: {
        disableAt,
      },
    },
  };
};
