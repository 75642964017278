import type { InMemoryCache, Reference } from '@apollo/client';
import type { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

import type { TrelloCard, TrelloCardCover } from '../generated';
import {
  isEnumString,
  isString,
  nullOrString,
} from '../plannerCardDataMapping/validateHelpers';
import type { RecursivePartial, TargetModel } from './cacheModelTypes';
import { syncNativeNestedObjectToRest } from './syncNativeNestedObjectToRest';
import { syncNativeToRestScalars } from './syncNativeToRestScalars';

const coverColors = [
  'BLACK',
  'BLUE',
  'GREEN',
  'LIME',
  'ORANGE',
  'PINK',
  'PURPLE',
  'RED',
  'SKY',
  'YELLOW',
];

/** Exported for testing only! */
export const fieldMappings = {
  brightness: {
    validate: (val: unknown) =>
      val === null || isEnumString(val, ['DARK', 'LIGHT']),
    transform: (val: string | null) =>
      isString(val) ? val.toLowerCase() : val,
  },
  color: {
    validate: (val: unknown) => val === null || isEnumString(val, coverColors),
    transform: (val: string | null) =>
      isString(val) ? val.toLowerCase() : val,
  },
  edgeColor: {
    validate: nullOrString,
  },
  size: {
    validate: (val: unknown) =>
      val === null || isEnumString(val, ['FULL', 'NORMAL']),
    transform: (val: string | null) =>
      isString(val) ? val.toLowerCase() : val,
  },
  sharedSourceUrl: {
    validate: nullOrString,
  },
};

const generateCardCoverFragment = (field: string) => {
  return `fragment CardCover${field}Write on Card {
    id
    cover {
      ${field}
    }
  }`;
};

const generateCardCoverData = (id: string, field: string, value: unknown) => {
  return {
    __typename: 'Card',
    id,
    cover: {
      __typename: 'Card_Cover',
      [field]: value,
    },
  };
};

/**
 * TODO:
 * attachment.id --> idAttachment
 * powerUp.id --> idPlugin
 * uploadedBackground.id --> idUploadedBackground
 */

/**
 * Given a native TrelloCard, syncs the card cover to the Card
 * model in the Apollo Cache
 * @param card The target Card model to write to
 * @param incoming The native TrelloCard data
 * @param cache The cache to write to
 * @param readField A function to read fields from cache references
 */
export const syncCardCover = (
  card: TargetModel,
  incoming: RecursivePartial<TrelloCard> | Reference,
  cache: InMemoryCache,
  readField: ReadFieldFunction,
) => {
  const cardCover = readField<TrelloCardCover | null>('cover', incoming);

  if (cardCover === undefined) {
    return;
  }

  if (cardCover === null) {
    // Technically both Card.cover and TrelloCard.cover are nullable,
    // so we allow this. In reality though, this should never happen because
    // even a card with no cover has cover.brightness and cover.size set
    syncNativeToRestScalars(
      card,
      { cover: { validate: (val: unknown) => val === null } },
      incoming,
      cache,
      readField,
    );
    return;
  }

  syncNativeNestedObjectToRest(
    card,
    fieldMappings,
    generateCardCoverFragment,
    generateCardCoverData,
    cardCover,
    cache,
  );
};
