import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useFeatureGate } from '@trello/feature-gate-client';
import { useLazyComponent } from '@trello/use-lazy-component';

import { WorkspaceNavigationError } from './WorkspaceNavigationError';

export const LazyWorkspaceNavigation: FunctionComponent<object> = () => {
  const { value: isM2WorkspaceNavEnabled } = useFeatureGate(
    'billplat_m2_workspace_pages',
  );

  const WorkspaceNavigation = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "workspace-navigation" */ './WorkspaceNavigation'
      ),
    { namedImport: 'WorkspaceNavigation' },
  );

  const WorkspaceSettingsNavigation = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "workspace-settings-navigation" */ './WorkspaceSettingsNavigation'
      ),
    { namedImport: 'WorkspaceSettingsNavigation' },
  );

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Workspace Navigation',
      }}
      errorHandlerComponent={WorkspaceNavigationError}
    >
      <Suspense fallback={null}>
        <ChunkLoadErrorBoundary fallback={<WorkspaceNavigationError />}>
          {isM2WorkspaceNavEnabled ? (
            <WorkspaceSettingsNavigation />
          ) : (
            <WorkspaceNavigation />
          )}
        </ChunkLoadErrorBoundary>
      </Suspense>
    </ErrorBoundary>
  );
};
